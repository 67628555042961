import { lazy, Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import { AppLoader } from '../../components/app-loader'

const PortfolioUtilizationPage = lazy(() => import('./portfolio-utilization'))
const PortfolioComparisonPage = lazy(() => import('./portfolio-comparison'))

export function PortfolioRoutes() {
  return (
    <Suspense fallback={<AppLoader />}>
      <Routes>
        <Route path='utilization' element={<PortfolioUtilizationPage />} />
        <Route path='comparison' element={<PortfolioComparisonPage />} />
      </Routes>
    </Suspense>
  )
}

import { putUserData } from '@lonerooftop/kitt-data'

/**
 * Feature flags are retrieved per client via `getPrivateFeatureFlags`
 * The result of this API call is passed to this class.
 * Additionally, privileged users can toggle their own feature flags.
 * This is stored via `postUserData` and retrieved via `getUserData`.
 * The final feature flags are computed by merging both states.
 *
 * NOTE - we store feature flags as an array of tuples: ["featureId", true].
 *
 **/

export class FeatureFlags {
  #userFlags = new Map();
  #clientFlags = new Map();
  #onChange = () => {};

  // use init instead
  // constructor () {}

  reset () {
    this.#userFlags = new Map()
    this.#onChange()
  }

  init (clientData, userData, onChange) {
    validateFeatureFlags(clientData)

    // default feature-flag configuration for the current client
    this.#clientFlags = new Map(clientData)

    // feature-flag configuration for the user
    this.#userFlags = new Map(userData)

    // called whenever the user sets something
    this.#onChange = () => {
      onChange()

      putUserData({ feature_flags: Array.from(this.#userFlags) }).then(response => {
        // saved, ignore the output console.log(response?.result?.success)
      }).catch(error => {
        // not saved
        console.error('something went wrong', error)
      })
    }

    // clean up feature flags that aren't used anymore
    // first, remove them locally
    this.#userFlags.forEach(( _status, id ) => {
      if (!this.#clientFlags.has(id)) {
        this.#userFlags.delete(id)
      }
    })

    // if we removed local user flags, synchronize it
    if (this.#userFlags.size < userData.length) {
      this.#onChange()
    }
  }

  enabled (id) {
    return Boolean(this.getAll().get(id))
  }

  getAll () {
    let merged = Array.from(this.#clientFlags).map(([ id, status ]) => {
      if (this.#userFlags.has(id)) {
        return [ id, this.#userFlags.get(id) ]
      } else {
        return [ id, status ]
      }
    })
    return new Map(merged)
  }

  set (id, status) {
    if (this.#clientFlags.has(id)) {
      this.#userFlags.set(id, status)
      this.#onChange()
    }
  }

  setAll (flags) {
    flags.forEach(([ id, status ]) => {
      if (this.#clientFlags.has(id)) {
        this.#userFlags.set(id, status)
      }
    })

    this.#onChange()
  }
}

function validateFeatureFlags (features) {
  console.assert(
    Array.isArray(features)
    && features.every(f =>
      Array.isArray(f)
      && f.length === 2
      && typeof f[0] === 'string'
      && typeof f[1] === 'boolean'
    ), 'features should be an array of tuples')
  console.assert(new Set(features.map(f => f[0])).size === features.length, 'features should be unique')
}

const feature_flags = new FeatureFlags()

export default feature_flags

import { login } from '@lonerooftop/kitt-data'
import { Button, PrimaryButton } from '../../../components/button'
import { CardWrapper, CardContent } from '../../../components/cards'
import { Logo } from '../../../components/app/logo'

export const LoginBox = ({ config, children }) => {
  let [{ name, url }, ...loginUrls] = config['login-endpoints']

  return (
    <div className='login-page'>
      <Logo />
      <CardWrapper variant='primary' position='center' title='Please login to continue'>
        <CardContent>
          {children}

          <PrimaryButton align='center' display='block' mb={4} onClick={() => login(url)}>
            {name}
          </PrimaryButton>
          {loginUrls.map(({ name, url }) =>
            <Button display='block' key={name} mb={2} align='center' onClick={() => login(url)}>
              {name}
            </Button>
          )}
        </CardContent>
      </CardWrapper>
    </div>
  )
}

import { FaMoon, FaSun } from 'react-icons/fa'
import { Button } from '../button'

import { useDarkmode } from '../../hooks/use-preferences'

const ICON = {
  dark: <FaMoon />,
  light: <FaSun />
}

const TOGGLE = {
  dark: 'light',
  light: 'dark'
}

export function ControlDarkMode () {
  let [ darkmode = 'light', setDarkmode ] = useDarkmode()

  return (
    <Button
      onClick={() => setDarkmode(TOGGLE[darkmode])}
      icon={ICON[darkmode]}
      title={`Switch to ${TOGGLE[darkmode]} mode`}
    />
  )
}
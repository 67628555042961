import { createInterval, createDatetime } from './date-utils'

export const formatTimeMarkerFields = (timeMarkerFields = {}) => {
  const spaceIds = timeMarkerFields.spaceIds.map((spaceId) =>
    (typeof spaceId === 'string' && spaceId.startsWith('campus')) ? spaceId.replace('campus:', '') : spaceId
  )
  return {
    space_ids: spaceIds,
    title: timeMarkerFields.title,
    description: timeMarkerFields.description,
    startdatetime: timeMarkerFields.startDateTime.toISODate(),
    enddatetime: timeMarkerFields.endDateTime.plus({ days: 1 }).toISODate(),
  }
}

export const formatTimeMarkersResponse = (timemarkers = [], store) => {
  return timemarkers?.map((timeMarker) => {
    return {
      id: timeMarker.id,
      spaceIds: timeMarker.space_ids
        .map(id => store.has(id) ? id : store.has(`campus:${id}`) ? `campus:${id}` : null)
        .filter(Boolean),
      title: timeMarker.title,
      description: timeMarker.description,
      startDateTime: createDatetime(timeMarker.startdatetime),
      endDateTime: createDatetime(timeMarker.enddatetime).minus({ days: 1 }),
    }
  })
}

export const locationDrilldown = (space) => {
  // TODO: discuss how to handle displaying multiple spaces
  let result = space.name
  switch (space.type) {
    case 'zone':
      result = `${space.campus_name} > ${space.building_name} > ${space.floor_name} > ${result}`
      break
    case 'floor':
      result = `${space.campus_name} > ${space.building_name} > ${result}`
      break
    case 'building':
      result = `${space.campus_name} > ${result}`
      break
    default:
      break
  }
  return result
}

/**
 * Checks if a new time marker has a match in a list of existing time markers based on the provided criteria.
 *
 * @param {Object} newMarker - The new time marker to check.
 * @param {luxon.DateTime} newMarker.startDateTime - The start date/time of the new marker (luxon DateTime object).
 * @param {luxon.DateTime} newMarker.endDateTime - The end date/time of the new marker (luxon DateTime object).
 * @param {Array<string>} newMarker.spaceIds - The space IDs associated with the new marker.
 * @param {string} newMarker.title - The title of the new marker.
 * @param {string} newMarker.description - The description of the new marker.
 *
 * @param {Array<Object>} allTimeMarkers - An array of existing time markers to compare against.
 * @param {luxon.DateTime} allTimeMarkers[].startDateTime - The start date/time of an existing marker (luxon DateTime object).
 * @param {luxon.DateTime} allTimeMarkers[].endDateTime - The end date/time of an existing marker (luxon DateTime object).
 * @param {Array<string>} allTimeMarkers[].spaceIds - The space IDs associated with an existing marker.
 * @param {string} allTimeMarkers[].title - The title of an existing marker.
 * @param {string} allTimeMarkers[].description - The description of an existing marker.
 *
 * @param {boolean} checkExactDates - If true, checks for an exact match of start and end dates. Otherwise, checks for overlap.
 *
 * @returns {boolean} - Returns true if a matching time marker is found, otherwise false.
 *
 * @throws {Error} - Throws an error if input date formats are invalid or missing required properties.
 */
export const hasMatchingTimeMarker = (newMarker, allTimeMarkers, checkExactDates) => {
  return allTimeMarkers.some((marker => {
    // if in future we decide to support time (not just date), need to refactor matchingDates check
    const currMarkerStart = marker.startDateTime
    const currMarkerEnd = marker.endDateTime
    const newMarkerStart = newMarker.startDateTime
    const newMarkerEnd = newMarker.endDateTime
    let matchingDates = false
    if (checkExactDates) {
      const matchingStart = currMarkerStart.hasSame(newMarkerStart, 'day')
      const matchingEnd = currMarkerEnd.hasSame(newMarkerEnd, 'day')
      matchingDates = matchingStart && matchingEnd
    } else {
      // Plus 1 day to ensure range is valid when dates are the same
      const newMarkerInterval = createInterval(newMarkerStart, newMarkerEnd.plus({ days: 1 }))
      matchingDates = createInterval(currMarkerStart, currMarkerEnd.plus({ days: 1 })).overlaps(newMarkerInterval)
    }
    // TODO: handle arrays of multiple spaces in any order
    const matchingSpaces = JSON.stringify(marker.spaceIds) === JSON.stringify(newMarker.spaceIds)
    const matchingTitle = marker.title === newMarker.title
    const matchingDescription = marker.description === newMarker.description
    return matchingDates && matchingSpaces && matchingTitle && matchingDescription
  }))
}

export const generateSelectedSpaces = (selected, space) => {
  const result = { ...selected }
  switch (space.type) {
    // TODO: need to dedupe when handling multiple selected
    case 'zone':
      result.campus.push(space.campus_id)
      result.building.push(space.building_id)
      result.floor.push(space.floor_id)
      result.zone.push(space.id)
      break
    case 'floor':
      result.campus.push(space.campus_id)
      result.building.push(space.building_id)
      result.floor.push(space.id)
      break
    case 'building':
      result.campus.push(space.campus_id)
      result.building.push(space.id)
      break
    case 'campus':
      result.campus.push(space.id)
      break
    default:
      break
  }
  return result
}

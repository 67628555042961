import { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { AppLoader } from '../../components/app-loader'
import { CallbackPage } from '../auth/pages/callback-page'
import { UnauthorizedPage } from '../auth/pages/unauthorized-page'
import { NotFoundPage } from '../not-found/not-found-page'

const WallyPage = lazy(() => import('./wally-page'))

export function WallyRoutes () {
  return (
    <Routes>
      <Route path='auth/callback' element={<CallbackPage />} />
      <Route path='forbidden/*' element={<UnauthorizedPage />} />
      <Route path='/' index element={
        <Suspense fallback={<AppLoader />}>
          <WallyPage />
        </Suspense>
      } />
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  )
}

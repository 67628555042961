const IS_GUID = /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/
const IS_INTEGER = /^[0-9]+$/
const IS_CAMPUS = /^campus/

/**
 * The getBuildings and getZones API will return ID's as either integer or GUID,
 * and those ID's are used as-is in our application.
 * They will be serialized in the URL though, and thus we need to parse them there.
 *
 **/
export function parseId (input) {
  // integers (used by Space) are returned as-is
  if (Number.isFinite(input)) {
    return input
  }

  // if it's only numbers, return a number
  if (IS_INTEGER.test(input)) {
    return parseInt(input, 10)
  }

  // GUID strings are returned as-is
  if (IS_GUID.test(input) || IS_CAMPUS.test(input)) {
    return input
  }

  return null
}

export function getId (space) {
  if (Array.isArray(space)) {
    return space.map(getId)
  }
  return space?.id ?? null
}

/**
 * getSpaceNameHierarchy prints the name of the space with all of its parents prefixed to the name
 * E.g. `Ank-Morpork › HEM › Floor 0 › 001 - Lecture Theater
 *
 * @space: object with (at least) a name property
 * @numberOfParents: (optional) how many parent(s) to show. By default, all parents will be shown.
 *
 * Example:
 * ```
 * let space = {
 *   name: '001 - Lecture Theater',
 *   floor_name: 'Floor 0',
 *   building_name: 'HEM',
 *   campus_name: 'Ank-Morpork'
 * }
 *
 * getSpaceNameHierarchy (space)    // `Ank-Morpork › HEM › Floor 0 › 001 - Lecture Theater`
 * getSpaceNameHierarchy (space, 3) // `Ank-Morpork › HEM › Floor 0 › 001 - Lecture Theater`
 * getSpaceNameHierarchy (space, 2) // `HEM › Floor 0 › 001 - Lecture Theater`
 * getSpaceNameHierarchy (space, 1) // `Floor 0 › 001 - Lecture Theater`
 * getSpaceNameHierarchy (space, 0) // `001 - Lecture Theater`
 *
 * ```
 *
 **/
export function getSpaceNameHierarchy (space, numberOfParents = 3) {
  if (!space?.name) return ''

  let { name, floor_name, building_name, campus_name, type } = space
  if (type === 'tag') {
    return name
  }

  let validParents = [campus_name, building_name, floor_name, name].filter(Boolean)
  let remainingParents = validParents.slice(Math.max(0, validParents.length - ++numberOfParents))
  return remainingParents.join(' › ')
}

const HOSTNAME = (() => {
  let hostname = global?.location?.hostname
  if (!hostname || hostname === 'localhost') {
    return 'bid-next.local.pie-lot.com'
  }
  return hostname
})()

export const DEVELOPMENT = HOSTNAME.endsWith('.pie-lot.com')
export const NEXT = !DEVELOPMENT && /^(bid|dashboard)-next/.test(HOSTNAME)
export const STAGING = !DEVELOPMENT && /^(bid|dashboard)-staging/.test(HOSTNAME)
export const TESTING = !!window.Cypress
export const PRODUCTION = !DEVELOPMENT && !NEXT && !STAGING
export const CLIENT = HOSTNAME.split('.')[1].toLowerCase()

console.log({ TESTING, HOSTNAME, DEVELOPMENT, NEXT, STAGING, PRODUCTION, CLIENT })

if (PRODUCTION) {
  const noop = () => {}
  window.console.assert = noop
  window.console.debug = noop
  // window.console.error = noop
  window.console.group = noop
  window.console.groupEnd = noop
  window.console.log = noop
  window.console.time = noop
  window.console.timeEnd = noop
  window.console.warn = noop
} else {
  // const oldConsole = window.console
  // window.console.log = oldConsole.log.bind(oldConsole)
  // window.console.assert = oldConsole.assert.bind(oldConsole)
  // window.console.warn = oldConsole.warn.bind(oldConsole)
  // window.console.error = oldConsole.error.bind(oldConsole)
}

import { useParams, useOutletContext } from 'react-router-dom'
import { Box, Text } from '@lonerooftop/kitt-ui'
import { CardHeader, CardContent } from '../../../../components/cards'
import {
  DialogHeading,
  DialogContent,
  DialogClose,
  Dialog,
} from '../../../../components/dialog'
import { PrimaryButton } from '../../../../components/button'
import { Message } from '../../../../components/message'
import { formatTimeMarkerFields, hasMatchingTimeMarker } from '../../../../utils/time-marker-utils'
import { TimeMarkerFields } from './time-marker-fields'
import { useTimeMarkers } from './use-time-markers'

export const EditTimeMarker = () => {

  const { id: urlId } = useParams()
  const {
    allTimeMarkers,
    putTimeMarkersRequest,
    errorMessage,
    setErrorMessage,
    loading,
  } = useOutletContext()

  const foundTimeMarker = allTimeMarkers.find(
    (timeMarker) => timeMarker.id === urlId
  )

  const {
    timeMarkerFields,
    updateTimeMarkerFields,
    setFromTo,
    closePopover,
    selectedSpaces,
    onChangeSpace,
    campusItems,
    buildingItems,
    floorItems,
    zoneItems,
  } = useTimeMarkers(foundTimeMarker)

  if (!foundTimeMarker) {
    return (
      <Dialog open={!foundTimeMarker} onOpenChange={closePopover}>
        <DialogContent>
          <CardHeader display='flex' alignItems='center'>
            <DialogHeading flex={1}>Something went wrong</DialogHeading>
            <DialogClose />
          </CardHeader>
          <CardContent>
            <Box mb={3}>
              <Text>No time marker found</Text>
            </Box>
            <PrimaryButton
              onClick={closePopover}
              className='width-100'
            >
              OK
            </PrimaryButton>
          </CardContent>
        </DialogContent>
      </Dialog>
    )
  }

  const updateTimeMarker = () => {
    const timeMarkersToCheck = allTimeMarkers.filter(timeMarker => timeMarker.id !== foundTimeMarker.id)
    if (hasMatchingTimeMarker(timeMarkerFields, timeMarkersToCheck)) {
      setErrorMessage(
        'A time marker with the same title, space and dates already exists.'
      )
    } else {
      setErrorMessage(null)
      putTimeMarkersRequest({
        ...formatTimeMarkerFields(timeMarkerFields),
        id: timeMarkerFields.id,
      })
    }
  }

  return (
    <Dialog open={timeMarkerFields.id !== ''} onOpenChange={closePopover}>
      <DialogContent>
        <CardHeader display='flex' alignItems='center'>
          <DialogHeading flex={1}>Edit time marker</DialogHeading>
          <DialogClose />
        </CardHeader>
        <CardContent data-test-id='edit-time-marker'>
          {errorMessage && (
            <Message type='error' title={<Text fontSize={6} mb={0}>{errorMessage}</Text>} />
          )}
          <TimeMarkerFields
            mainCTA={updateTimeMarker}
            mainCTALabel='Update'
            timeMarkerFields={timeMarkerFields}
            updateTimeMarkerFields={updateTimeMarkerFields}
            setFromTo={setFromTo}
            selectedSpaces={selectedSpaces}
            onChangeSpace={onChangeSpace}
            campusItems={campusItems}
            buildingItems={buildingItems}
            floorItems={floorItems}
            zoneItems={zoneItems}
            loading={loading}
            hasFieldsChanged={!hasMatchingTimeMarker(timeMarkerFields, [foundTimeMarker], true)}
          />
        </CardContent>
      </DialogContent>
    </Dialog>
  )
}

export default EditTimeMarker

import { Text, H4 } from '@lonerooftop/kitt-ui'
import { truncate } from '../utils/format'
import { forwardRef } from 'react'

export const Badge = ({ children }) => (
  <span className='badge'>{children}</span>
)

export const Label = forwardRef((props, ref) =>
  <Text ref={ref} as='label' className='label' {...props} />)

export const Title = (props) =>
  <H4 className='title' {...props} />

export const SmallTitle = (props) =>
  <H4 className='small-title' {...props} />

export const StickyTitle = ({ children }) => (
  <H4 className='sticky-title'>
    {children}
  </H4>
)

export const SubTitle = (props) =>
  <H4
    detail
    fontWeight='medium'
    fontSize={5}
    mb={0}
    {...props}
  />

export const BigFatTitle = (props) =>
  <H4
    fontSize={3}
    fontWeight='bold'
    {...props}
  />

export const Truncate = ({ children, text = '', maxlength = Infinity }) => {
  if (typeof children === 'string' && !text) {
    text = children
  }

  return truncate(text, maxlength)
}

import isSameYear from 'date-fns/isSameYear'
import setMonth from 'date-fns/setMonth'
import setYear from 'date-fns/setYear'
import startOfMonth from 'date-fns/startOfMonth'
import startOfYear from 'date-fns/startOfYear'

import { Flex, Box, SmallDetail, Icon } from '@lonerooftop/kitt-ui'
import { CaptionNavigation, Dropdown, DayPicker, useDayPicker, useNavigation } from 'react-day-picker'
import { Button } from '../button'
import { DateTimeFormat } from '../datetime'
import { Tooltip } from '../tooltip'
import { FaCaretDown } from 'react-icons/fa'

import { useCallback, useEffect, useState } from 'react'
import { useStore } from '../../hooks/use-store'
import { useStartDate, useEndDate, useIds, useSetDates } from '../../hooks/use-page-settings'

import { useFloating, autoUpdate, flip, shift, useClick, useDismiss, useRole, useInteractions, FloatingFocusManager } from '@floating-ui/react'

import { DateTime } from 'luxon'
import { createToday } from '../../utils/date-utils'

const format = { year: 'numeric', month: 'short', day: 'numeric' }
const dayFormat = { year: 'numeric', month: 'short', day: 'numeric', weekday: 'long' }

/**
 * URL Query component
 *
 **/
let yesterday = createToday().minus({ days: 1 }).toJSDate()
export function SelectQueryDateRangePicker () {
  let {ids} = useIds()
  let startdatetime = useStartDate()
  let enddatetime = useEndDate()
  let setDates = useSetDates()
  let store = useStore()
  let dateRange = store.getMinMaxDate(ids)
  let minDate = DateTime.fromISO(dateRange[0]).toJSDate()
  let maxDate = DateTime.fromISO(dateRange[1]).toJSDate()

  let setFromTo = useCallback((range) => {
    if (!range?.from || !range?.to) {
      return
    }

    let from = DateTime.fromJSDate(range.from).toISODate()
    let to = DateTime.fromJSDate(range.to).plus({ days: 1 }).toISODate()
    if (from !== startdatetime || to !== enddatetime) {
      setDates(from, to)
    }
  }, [startdatetime, enddatetime, setDates ])

  return (
    <SelectDateRangePicker
      key={`${startdatetime}-${enddatetime}`}
      fromDate={DateTime.fromISO(startdatetime).toJSDate()}
      toDate={DateTime.fromISO(enddatetime).minus({ days: 1 }).toJSDate()}
      minDate={minDate}
      maxDate={maxDate < yesterday ? maxDate : yesterday}
      setFromTo={setFromTo}
    />
  )
}

/**
 * Pure UI component that provides date range picker
 *
 **/
export function SelectDateRangePicker (props) {
  let {
    minDate,
    maxDate,
    fromDate,
    toDate,
    setFromTo,
    buttonClass
  } = props
  let [range, setRange] = useState({ from: fromDate, to: toDate })

  // make this a controlled component so we can update the dates
  // when hiding the calendar
  let [visible, setVisible] = useState()

  const { refs, floatingStyles, context } = useFloating({
    open: visible,
    onOpenChange: setVisible,
    middleware: [flip(), shift()],
    whileElementsMounted: autoUpdate,
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ])

  let footer = (
    <DayPickerFooter
      range={range}
      onApply={() => {
        setVisible(false)
        setFromTo(range)
      }}
    />
  )

  // we don't use onSelect so we can implement our own addToRange logic
  let onDayClick = (day, activeModifiers, e) => {
    const nextRange = addToRange(day, range)
    setRange(nextRange)
  }

  let onWeekNumberClick = (weekNumber, days) => {
    setRange({from: days[0], to: days[days.length - 1] })
  }

  let isSingleDay = (toDate - fromDate) < 86400000 // 26 hours in milliseconds

  return (
    <>
      <button
        className={`${buttonClass} button button-date-picker${visible ? ' button-active' : ''}`}
        ref={refs.setReference}
        style={{ minWidth: '16rem' }}
        {...getReferenceProps()}
      >
        {isSingleDay
          ? <DateTimeFormat date={fromDate} format={dayFormat} />
          :
          <>
            <DateTimeFormat date={fromDate} format={format} />
            <SmallDetail> – </SmallDetail>
            <DateTimeFormat date={toDate} format={format} />
          </>}
        <Icon ml={2} fontSize={8}>
          <FaCaretDown className={visible ? 'rotatable rotate-180' : 'rotatable'} />
        </Icon>
      </button>
      {visible && (
        <FloatingFocusManager context={context}>
          <div
            className='card card-primary date-range-container'
            ref={refs.setFloating}
            style={floatingStyles}
            role='dialog'
            {...getFloatingProps()}
          >
            <DayPicker
              components={{
                Caption,
                CaptionLabel,
                IconDropdown,
                WeekNumber
              }}
              defaultMonth={fromDate}
              fixedWeeks
              footer={footer}
              fromDate={minDate}
              initialFocus
              mode='range'
              onDayClick={onDayClick}
              onWeekNumberClick={onWeekNumberClick}
              selected={range}
              showOutsideDays
              showWeekNumber
              toDate={maxDate}
              weekStartsOn={1}
              classNames={{
                button: 'button',
              }}
              modifiersClassNames={{
                hidden: 'button-transparent',
                selected: 'button-active',
                today: 'button-primary'
              }}
            />
          </div>
        </FloatingFocusManager>
      )}
    </>
  )
}

function Caption ({ id, displayMonth }) {
  const { classNames, styles } = useDayPicker()
  return (
    <Box
      className={classNames.caption}
      style={styles.caption}
      align='center'
      position='relative'
    >
      <CaptionNavigation displayMonth={displayMonth} id={id} />
      <CaptionDropdowns displayMonth={displayMonth} id={id} />
    </Box>
  )
}

function CaptionLabel () {
  return null
}

function IconDropdown () {
  return null
}

function DayPickerFooter ({ onApply, range = {} }) {
  return (
    <Tooltip title={<>Tip: press the <kbd>Enter</kbd> key to apply or <kbd>Esc</kbd> to cancel</>}>
      <Flex mt={3} alignItems='center'>
        <Box
          align='center'
          flex={1}
          mr={3}
          fontSize={7}
          fontWeight='bold'
          p={2}
        >
          {range.from
            ? <DateTimeFormat date={range.from} format={format} />
            : ''}
          –
          {range.to
            ? <DateTimeFormat date={range.to} format={format} />
            : 'select end date'}
        </Box>
        <Button disabled={!range.from || !range.to} display='inline-flex' className='button-primary' onClick={onApply}>
          Apply time frame
        </Button>
      </Flex>
    </Tooltip>
  )
}

/**
 * Render the week number element. If `onWeekNumberClick` is passed to DayPicker, it
 * renders a button, otherwise a span element.
 *
 * Originally adapted from https://github.com/gpbl/react-day-picker/blob/v8.5.1/packages/react-day-picker/src/components/WeekNumber/WeekNumber.tsx
 */
function WeekNumber (props) {
  const { number: weekNumber, dates } = props
  const {
    fromDate,
    toDate,
    onWeekNumberClick,
    styles,
    classNames,
    locale,
    labels: { labelWeekNumber },
    formatters: { formatWeekNumber }
  } = useDayPicker()

  const content = formatWeekNumber(Number(weekNumber), { locale })

  if (!onWeekNumberClick) {
    return (
      <span className={classNames.weeknumber} style={styles.weeknumber}>
        {content}
      </span>
    )
  }

  const label = labelWeekNumber(Number(weekNumber), { locale })

  const handleClick = function (e) {
    onWeekNumberClick(
      weekNumber,
      // OURS - only select dates inside our range
      dates.filter(date => date >= fromDate && date <= toDate),
      e
    )
  }

  return (
    <Button
      name='week-number'
      type='button'
      aria-label={label}
      className={classNames.weeknumber}
      style={styles.weeknumber}
      onClick={handleClick}
      // OURS - disable weeks that are out of our range
      disabled={dates.every(date => date < fromDate || date > toDate)}
    >
      {content}
    </Button>
  );
}

/**
 * Add a day to an existing range.
 * Reset the range if from & to are already set
 */
function addToRange(day, range) {
  const { from, to } = range || {}

  switch (true) {
    case !from:
      return { from: day, to }
    // allow selecting ranges backwards in time
    case !to && day < from:
      return { from: day, to: from }
    case !to:
      return { from, to: day }
    default:
      return { from: day }
  }
}

function CaptionDropdowns(props) {
  const { classNames, styles } = useDayPicker()
  const { goToMonth } = useNavigation()

  const handleMonthChange = (newMonth) => {
    goToMonth(newMonth)
  }

  return (
    <div
      className={classNames.caption_dropdowns}
      style={styles.caption_dropdowns}
    >
      <MonthsDropdown
        onChange={handleMonthChange}
        displayMonth={props.displayMonth}
      />
      <YearsDropdown
        onChange={handleMonthChange}
        displayMonth={props.displayMonth}
      />
    </div>
  )
}


/** Render the dropdown to navigate between months. */
export function MonthsDropdown(props) {
  const {
    fromDate,
    toDate,
    styles,
    locale,
    formatters: { formatMonthCaption },
    classNames,
    components,
    labels: { labelMonthDropdown }
  } = useDayPicker()

  // when displayMonth is changed to an out-of-range month, reset it to the earliest month
  let value = props.displayMonth.getMonth()
  let setFromMonth = isSameYear(props.displayMonth, fromDate) && value < fromDate.getMonth()
  let setToMonth = isSameYear(props.displayMonth, toDate) && value > toDate.getMonth()
  let onChange = props.onChange
  useEffect(() => {
    if (setFromMonth) {
      onChange(fromDate)
    }

    if (setToMonth) {
      onChange(toDate)
    }
  }, [ onChange, fromDate, toDate, setFromMonth, setToMonth ])

  // Dropdown should appear only when both from/toDate is set
  if (!fromDate) return <></>
  if (!toDate) return <></>

  const dropdownMonths = []

  let startMonth = 0
  let endMonth = 11
  let date = startOfMonth(props.displayMonth)

  if (isSameYear(props.displayMonth, fromDate) || isSameYear(fromDate, toDate)) {
    startMonth = fromDate.getMonth()
  }

  if (isSameYear(props.displayMonth, toDate) || isSameYear(fromDate, toDate)) {
    endMonth = toDate.getMonth()
  }

  for (let month = startMonth; month <= endMonth; month++) {
    dropdownMonths.push(setMonth(date, month))
  }

  const handleChange = (e) => {
    const selectedMonth = Number(e.target.value)
    const newMonth = setMonth(startOfMonth(props.displayMonth), selectedMonth)
    props.onChange(newMonth)
  }

  const DropdownComponent = components?.Dropdown ?? Dropdown

  return (
    <DropdownComponent
      name="months"
      aria-label={labelMonthDropdown()}
      className={classNames.dropdown_month}
      style={styles.dropdown_month}
      onChange={handleChange}
      value={props.displayMonth.getMonth()}
      caption={
        <DropdownButton>
          {formatMonthCaption(props.displayMonth, { locale })}
        </DropdownButton>
      }
    >
      {dropdownMonths.map((m) => (
        <option key={m.getMonth()} value={m.getMonth()}>
          {formatMonthCaption(m, { locale })}
        </option>
      ))}
    </DropdownComponent>
  )
}

function DropdownButton ({ children }) {
  return (
    <span className='button'>
      {children}
      <Icon ml={2} fontSize={8}>
        <FaCaretDown />
      </Icon>
    </span>
  )
}

/**
 * Copy/paste from react-day-picker/src/components/YearsDropdown v8.3.7
 */
export function YearsDropdown(props) {
  const { displayMonth } = props
  const {
    fromDate,
    toDate,
    locale,
    styles,
    classNames,
    components,
    formatters: { formatYearCaption },
    labels: { labelYearDropdown }
  } = useDayPicker()

  const years = []

  // Dropdown should appear only when both from/toDate is set
  if (!fromDate) return <></>
  if (!toDate) return <></>

  const fromYear = fromDate.getFullYear()
  const toYear = toDate.getFullYear()
  for (let year = fromYear; year <= toYear; year++) {
    years.push(setYear(startOfYear(new Date()), year))
  }

  const handleChange = (e) => {
    const newMonth = setYear(
      startOfMonth(displayMonth),
      Number(e.target.value)
    )
    props.onChange(newMonth)
  }

  const DropdownComponent = components?.Dropdown ?? Dropdown

  return (
    <DropdownComponent
      name="years"
      aria-label={labelYearDropdown()}
      className={classNames.dropdown_year}
      style={styles.dropdown_year}
      onChange={handleChange}
      value={displayMonth.getFullYear()}
      caption={
        <DropdownButton>
          {formatYearCaption(displayMonth, { locale })}
        </DropdownButton>
      }
    >
      {years.map((year) => (
        <option key={year.getFullYear()} value={year.getFullYear()}>
          {formatYearCaption(year, { locale })}
        </option>
      ))}
    </DropdownComponent>
  )
}

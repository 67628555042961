import { PageContext } from '../context'

import { useCallback, useContext } from 'react'
import { useClientConfig } from './use-client-config'
import { usePreferences } from './use-preferences'
import { useStore } from './use-store'

import { addOrRemove } from '../utils/select-utils'
import { createZoneFilters } from '../utils/zone-utils'
import memo from 'memoize-one'

import { QUERY_PARAMS } from '../constants'

export function usePageSettings () {
  return useContext(PageContext)
}

/**
  * Convenience getters for specific query keys.
  *
  * These are the raw values as set in the URL,
  * with page-specific defaults and overrides mixed in.
  *
  * Smart getters are available in useStore.
 **/
export function useAggregation () {
  let { query } = usePageSettings()
  return query.get(QUERY_PARAMS.AGGREGATION)
}

export function useBookingSources () {
  let { query } = usePageSettings()
  let { booking_sources } = useClientConfig()
  return getBookingSources(query, booking_sources)
}

let getBookingSources = memo((query, booking_sources = []) => booking_sources.length === 1 ? booking_sources : query.get(QUERY_PARAMS.BOOKING_SOURCE))

export function useCapacity () {
  let { query } = usePageSettings()
  return query.get(QUERY_PARAMS.CAPACITY)
}

export function useDataFormat () {
  let { query } = usePageSettings()
  return query.get(QUERY_PARAMS.DATAFORMAT)
}

export function useDataSource () {
  let { query } = usePageSettings()
  return query.get(QUERY_PARAMS.DATASOURCE)
}

export function useDataSourceTypes () {
  let { query } = usePageSettings()
  return query.get(QUERY_PARAMS.DATASOURCE_TYPE)
}

export function useEndDate () {
  let { query } = usePageSettings()
  return query.get(QUERY_PARAMS.ENDDATETIME)
}

export function useFilterZonesWithoutCapacity () {
  let { query } = usePageSettings()
  return query.get(QUERY_PARAMS.FILTER_NO_CAPACITY)
}

export function useGroupBy () {
  let { query } = usePageSettings()
  return query.get(QUERY_PARAMS.GROUP_BY)
}

export function useIds () {
  let { query } = usePageSettings()
  let { preferences } = usePreferences()
  return getIds(query, preferences)
}

// use a memoized selector since we're (potentially) returning new arrays
let getIds = memo((query, preferences) => {
  let ids = query.get(QUERY_PARAMS.IDS)
  let campus = query.has(QUERY_PARAMS.CAMPUS)
    ? query.get(QUERY_PARAMS.CAMPUS)
    : false

  if (ids.length > 0) {
    return ids
  } else if (campus) {
    return [`campus:${campus}`]
  } else if (preferences.prefered_building) {
    return [preferences.prefered_building]
  } else {
    return []
  }
})

export function useInterval () {
  let { query } = usePageSettings()
  return query.get(QUERY_PARAMS.INTERVAL)
}

export function useIgnoreMissingData () {
  let { query } = usePageSettings()
  return query.get(QUERY_PARAMS.IGNORE_MISSING_DATA)
}

export function useOpeningDays () {
  let { query } = usePageSettings()
  return query.get(QUERY_PARAMS.OPENING_DAYS)
}

export function useOpeningEnd () {
  let { query } = usePageSettings()
  return query.get(QUERY_PARAMS.OPENING_END)
}

export function useOpeningHours () {
  let { query } = usePageSettings()
  return query.get(QUERY_PARAMS.OPENING_HOURS)
}

export function useOpeningStart () {
  let { query } = usePageSettings()
  return query.get(QUERY_PARAMS.OPENING_START)
}

export function useStartDate () {
  let { query } = usePageSettings()
  return query.get(QUERY_PARAMS.STARTDATETIME)
}

export function useTags () {
  let { query } = usePageSettings()
  return query.get(QUERY_PARAMS.ZONE_TAG)
}

export function useMetadata () {
  let { query } = usePageSettings()
  return query.get(QUERY_PARAMS.ZONE_METADATA)
}

export function useSpaceLayer () {
  let { query } = usePageSettings()
  return query.get(QUERY_PARAMS.SPACE_LAYER)
}

/** Setters **/
export function useSetAggregation () {
  let { setQuery } = usePageSettings()

  return useCallback(
    (input) => {
      setQuery({
        [QUERY_PARAMS.AGGREGATION]: input
      })
    },
    [ setQuery ]
  )
}

export function useSetBookingSources () {
  let { setQuery } = usePageSettings()
  let current = useBookingSources()

  return useCallback(
    (input) => {
      setQuery({
        [QUERY_PARAMS.BOOKING_SOURCE]: addOrRemove(input, current)
      })
    },
    [ current, setQuery ]
  )
}

export function useSetCapacity () {
  let { setQuery } = usePageSettings()

  return useCallback(
    (input) => {
      setQuery({
        [QUERY_PARAMS.CAPACITY]: /^#?default#?$/g.test(input) ? null : input
      })
    },
    [setQuery]
  )
}

export function useSetDataFormat () {
  let { setQuery } = usePageSettings()

  return useCallback(
    (input) => {
      console.assert(input === 'utilisation' || input === 'count')
      setQuery({
        [QUERY_PARAMS.DATAFORMAT]: input
      })
    },
    [setQuery]
  )
}

export function useSetDataSource () {
  let { setQuery } = usePageSettings()
  let ids = useIds()
  let store = useStore()
  let startdatetime = useStartDate()
  let enddatetime = useEndDate()

  return useCallback(
    (input) => {
      let nextQuery = {
        [QUERY_PARAMS.DATASOURCE]: input,
        [QUERY_PARAMS.DATASOURCE_TYPE]: null,
      }

      // when switching to datasource, if zone is selected that doesn't have that datasource, de-select it
      let [zone] = ids.map(id => store.zone(id, startdatetime, enddatetime))
      if (zone?.floor_id && zone?.layer_name !== input) {
        console.debug('selected zone doesn\'t have layer, selecting floor', { zone, input, ids })
        nextQuery[QUERY_PARAMS.IDS] = [zone.floor_id]
      }

      setQuery(nextQuery)
    },
    [setQuery, ids, startdatetime, enddatetime, store]
  )
}

export function useSetDataSourceType () {
  let { setQuery } = usePageSettings()
  let current = useDataSourceTypes()

  return useCallback(
    (input) => {
      setQuery({
        [QUERY_PARAMS.DATASOURCE_TYPE]: addOrRemove(input, current)
      })
    },
    [current, setQuery]
  )
}

export function useSetDates () {
  let { setQuery } = usePageSettings()

  return useCallback(
    (startdatetime, enddatetime) => {
      setQuery({
        [QUERY_PARAMS.STARTDATETIME]: startdatetime,
        [QUERY_PARAMS.ENDDATETIME]: enddatetime
      })
    },
    [setQuery]
  )
}

export function useSetEndDate () {
  let { setQuery } = usePageSettings()

  return useCallback(
    (input) => {
      setQuery({
        [QUERY_PARAMS.ENDDATETIME]: input
      })
    },
    [setQuery]
  )
}

export function useSetGroupBy () {
  let { setQuery } = usePageSettings()

  return useCallback(
    (input) => {
      setQuery({
        [QUERY_PARAMS.GROUP_BY]: input
      })
    },
    [setQuery]
  )
}

export function useSetIds (multiple) {
  let { query, setQuery } = usePageSettings()
  let store = useStore()
  let ids = useIds()

  return useCallback(
    (input = []) => {
      console.debug('handle setIds', {query, input, ids})
      let startdatetime = query.get(QUERY_PARAMS.STARTDATETIME)
      let enddatetime = query.get(QUERY_PARAMS.ENDDATETIME)
      let capacity = query.get(QUERY_PARAMS.CAPACITY)
      let datasource = query.get(QUERY_PARAMS.DATASOURCE)
      let datasourceTypes = query.get(QUERY_PARAMS.DATASOURCE_TYPE)
      let metadata = query.get(QUERY_PARAMS.ZONE_METADATA)
      let tags = query.get(QUERY_PARAMS.ZONE_TAG)

      let nextIds = (multiple || Array.isArray(input)) ? addOrRemove(input, ids) : [input]
      let nextQuery = {
        [QUERY_PARAMS.IDS]: nextIds,
        [QUERY_PARAMS.CAMPUS]: null,
      }

      // This is a naive approach to resetting filters.
      // We check what zones are created when applying ALL filters
      // and reset any set filter that doesn't have matching zones in the resulting set
      // This means that in cases when multiple filters are applied, or when a single filter will result
      // in 0 zones being selected (e.g. capacity=Seats when none of the zones have that capacity)
      // ALL filters will be reset.
      // NB. We could recompute the zones for every combination of filter(s)
      // and figure out what to reset, but that seems hard and convoluted.
      let filters = createZoneFilters(capacity, datasource, datasourceTypes, metadata, tags)
      let nextZones = nextIds
          .flatMap(id => store.zones(id, startdatetime, enddatetime))
          .filter(filters.every)

      // console.debug({ nextZones, capacity, datasource, datasourceTypes, metadata, tags })

      if (capacity && !nextZones.some(filters.capacity)) {
        // console.debug('reset capacity', { capacity, nextZones, filters })
        nextQuery[QUERY_PARAMS.CAPACITY] = null
      }

      if (datasource && !nextZones.some(filters.datasource)) {
        // console.debug('reset datasource', { datasource, nextZones, filters })
        nextQuery[QUERY_PARAMS.DATASOURCE] = null
      }

      if (datasourceTypes.length > 0 && !nextZones.some(filters.datasourceTypes)) {
        // console.debug('reset datasourceTypes', { datasourceTypes, nextZones, filters })
        nextQuery[QUERY_PARAMS.DATASOURCE_TYPE] = null
      }

      if (metadata.length > 0 && !nextZones.some(filters.metadata)) {
        // console.debug('reset metadata', { metadata, nextZones, filters })
        nextQuery[QUERY_PARAMS.ZONE_METADATA] = null
      }

      if (tags.length > 0 && !nextZones.some(filters.tags)) {
        // console.debug('reset tags', { tags, nextZones, filters })
        nextQuery[QUERY_PARAMS.ZONE_TAG] = null
      }

      // console.debug(nextQuery)
      setQuery(nextQuery)
    },
    [ ids, multiple, query, setQuery, store ]
  )
}

export function useSetFilterZonesWithoutCapacity () {
  let { setQuery } = usePageSettings()
  return useCallback(
    (input) => {
      setQuery({
        [QUERY_PARAMS.FILTER_NO_CAPACITY]: input
      })
    },
    [ setQuery ]
  )
}

export function useSetIgnoreMissingData () {
  let { setQuery } = usePageSettings()
  return useCallback(
    (input) => {
      setQuery({
        [QUERY_PARAMS.IGNORE_MISSING_DATA]: input
      })
    },
    [ setQuery ]
  )
}

export function useSetInterval () {
  let { setQuery } = usePageSettings()
  return useCallback(
    (input) => {
      setQuery({
        [QUERY_PARAMS.INTERVAL]: input
      })
    },
    [ setQuery ]
  )
}

export function useSetMetadata () {
  let { setQuery } = usePageSettings()
  let current = useMetadata()
  return useCallback(
    (input) => {
      setQuery({
        [QUERY_PARAMS.ZONE_METADATA]: addOrRemove(input, current)
      })
    },
    [ current, setQuery ]
  )
}

export function useSetSpaceLayer () {
  let { setQuery } = usePageSettings()

  return useCallback(
    (input) => {
      setQuery({
        [QUERY_PARAMS.SPACE_LAYER]: input
      })
    },
    [ setQuery ]
  )
}

export function useSetOpeningHours () {
  let { setQuery } = usePageSettings()

  return useCallback(
    (input) => {
      setQuery({
        [QUERY_PARAMS.OPENING_HOURS]: input
      })
    },
    [ setQuery ]
  )
}

export function useSetOpeningDays () {
  let { setQuery } = usePageSettings()
  let current = useOpeningDays()

  return useCallback(
    (input) => {
      setQuery({
        [QUERY_PARAMS.OPENING_DAYS]: addOrRemove(input, current)
      })
    },
    [ current, setQuery ]
  )
}

export function useSetOpeningStart () {
  let { setQuery } = usePageSettings()

  return useCallback(
    (input) => {
      setQuery({
        [QUERY_PARAMS.OPENING_START]: input
      })
    },
    [ setQuery ]
  )
}

export function useSetOpeningEnd () {
  let { setQuery } = usePageSettings()

  return useCallback(
    (input) => {
      setQuery({
        [QUERY_PARAMS.OPENING_END]: input
      })
    },
    [ setQuery ]
  )
}

export function useSetCustomOpeningHours () {
  let { setQuery } = usePageSettings()

  return useCallback(
    (openingHoursType, openingDays, openingStart, openingEnd) => {
      setQuery({
        [QUERY_PARAMS.OPENING_HOURS]: openingHoursType,
        [QUERY_PARAMS.OPENING_DAYS]: openingDays,
        [QUERY_PARAMS.OPENING_START]: openingStart,
        [QUERY_PARAMS.OPENING_END]: openingEnd
      })
    },
    [ setQuery ]
  )
}

export function useSetStartDate () {
  let { setQuery } = usePageSettings()

  return useCallback(
    (input) => {
      setQuery({
        [QUERY_PARAMS.STARTDATETIME]: input
      })
    },
    [setQuery]
  )
}

export function useSetTags () {
  let { setQuery } = usePageSettings()

  return useCallback(
    (input) => {
      setQuery({
        [QUERY_PARAMS.ZONE_TAG]: input
      })
    },
    [ setQuery ]
  )
}
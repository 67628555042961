import { DEFAULT_OPENING_HOURS, WEEKDAYS_SHORT } from '../constants'
import { createDatetime } from './date-utils'
import { getDailyOpeningHours } from '@wojtekmaj/opening-hours-utils'

// NB - this is just a dictionary so we can reference opening hours by ID rather than hardcode their values
// To get a list of user-selectable opening hours, use getOpeningHoursBySpace
const OPENING_HOURS = [
  { key: '24_7', title: '24h / 7 days', value: DEFAULT_OPENING_HOURS['24_7']},
  { key: '24_5', title: '24h / work days', value: DEFAULT_OPENING_HOURS['24_5']},
  { key: 'opening_hours', title: 'Building opening hours', value: DEFAULT_OPENING_HOURS['opening_hours'] },
  { key: 'office_hours', title: 'Office hours', value: DEFAULT_OPENING_HOURS['office_hours'] },
  { key: 'core_hours', title: 'Core use', value: DEFAULT_OPENING_HOURS['core_hours'] },
  { key: 'lunch_hours', title: 'Lunch', value: DEFAULT_OPENING_HOURS['lunch_hours'] },
  { key: 'morning', title: 'Morning', value: DEFAULT_OPENING_HOURS['morning'] },
  { key: 'afternoon', title: 'Afternoon', value: DEFAULT_OPENING_HOURS['afternoon'] },
  { key: 'weekend', title: 'Weekend', value: DEFAULT_OPENING_HOURS['weekend'] },
  { key: 'custom', title: 'Custom', value: DEFAULT_OPENING_HOURS['custom'] }
]

/**
 * Use this to get opening hours for a space.
 * Pass custom opening_hours to either filter on key/title/value or use custom array
 **/
export function getOpeningHoursBySpace (space) {
  if (!space) {
    return OPENING_HOURS
  }

  return OPENING_HOURS.map(({ key, title, value }) => ({
    key,
    title,
    value: space?.settings?.[key] ?? value
  }))
}

export function getOpeningHoursValue (key, space) {
  let hours = getOpeningHoursBySpace(space)
  let hour = hours.find(h => h.key === key || h.value === key)
  return hour?.value
}

export function createTimes (startHour, endHour) {
  const times = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]

  if (startHour < endHour) {
    // e.g. work times 9-17
    return times.filter(t => t >= startHour && t < endHour)
  } else if (startHour > endHour) {
    // e.g. night shifts 22-8
    return [
      ...times.filter(t => t >= startHour),
      ...times.filter(t => t < endHour),
    ]
  } else {
    return times
  }
}

export function createDays (days, opening_hours) {
  if (Array.isArray(days) && days.length > 0) {
    return days
  }
  
  days = opening_hours
    ?.split(', ')
    .flatMap(opening_hour => getDailyOpeningHours(opening_hour))
    .map(d => d.day)

  return Array.from(new Set(days))
}

export function getOpeningHoursOverlapWithDates (input, startdatetime, duration) {
  let opening_hours = getOpeningHoursValue(input) || input

  // no need to check dates since these are every day
  if (opening_hours === '24/7' || opening_hours.startsWith('24')) {
    return true
  }

  let start = createDatetime(startdatetime)

  // add day ranges
  let matchRange = /^[A-Z][a-z]-[A-Z][a-z]/
  let ranges = opening_hours.split(/,| /g).filter(part => matchRange.test(part))

  let opening_days = new Set(
    ranges.flatMap(range => {
      let [ start, end ] = range.split('-')
      return WEEKDAYS_SHORT.slice(
        WEEKDAYS_SHORT.indexOf(start),
        WEEKDAYS_SHORT.indexOf(end)
      )
    })
  )

  // add single days
  WEEKDAYS_SHORT.forEach(day => {
    if (opening_hours.includes(day)) {
      opening_days.add(day)
    }
  })

  if (opening_days.length === 0) {
    return true
  }

  let total_days = duration.as('days')
  let i = 0
  let result = false

  // console.log('checking', opening_hours, start.toISO(), opening_days)

  while (i < total_days) {
    if (result) {
      break
    } else {
      let day = start.plus({ days: i }).toFormat('ccc').substr(0, 2)
      // console.log('checking', i, day)
      result = opening_days.has(day)
    }

    i++
  }

  return result
}

import { DropdownMenu } from '../dropdown'

import { useSetIds, useStartDate, useEndDate } from '../../hooks/use-page-settings'
import { useStore, useSpaces } from '../../hooks/use-store'

import { naturalSortBy } from '../../utils/sort'
import { getId } from '../../utils/space-utils'

export function SelectQueryCampus () {
  let startdatetime = useStartDate()
  let enddatetime = useEndDate()
  let selected = useSpaces().campuses.map(getId)
  let onChange = useSetIds()

  let items = useStore()
    .campuses(null, startdatetime, enddatetime)
    .map(campus => {
      let datasources = new Set(campus.buildings.flatMap(building => building.zone_layers))
      let disabled = campus.buildings.length === 0 || datasources.size === 0

      return {
        key: campus.id,
        title: campus.name,
        disabled,
      }
    })
    .sort(naturalSortBy('title'))

  return (
    <DropdownMenu
      items={items}
      selected={selected}
      onChange={onChange}
      onClear={items.length > 1 ? onChange : undefined}
      title='Choose site'
    />
  )
}

import { DateTime, Duration } from 'luxon'
import PropTypes from 'prop-types'
import { Text, SmallDetail } from '@lonerooftop/kitt-ui'
import { createDatetime, getTimeUnit as getTimeUnitNew } from '../utils/date-utils'

export const DateTimeFormat = ({ date, format = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false }, ...rest }) =>
  <Text {...rest}>
    {createDatetime(date).toLocaleString(format)}
  </Text>

DateTimeFormat.propTypes = {
  date: (props, propName, componentName) => {
    let parsed = createDatetime(props[propName])
    if (!parsed.isValid) {
      return new Error(`Invalid date value supplied to ${componentName}: ${parsed.invalidExplanation}. Validation failed.`);
    }
  },
  format: PropTypes.object
}

export const StartEndTime = ({ startdatetime, enddatetime, format = DateTime.TIME_24_SIMPLE, ...rest }) =>
  <Text truncate {...rest}>
    {createDatetime(startdatetime).toLocaleString(format)}<SmallDetail> – </SmallDetail>{createDatetime(enddatetime).toLocaleString(format)}
  </Text>

export const DateTimeHour = ({ date, format = DateTime.TIME_24_SIMPLE, ...rest }) =>
  <Text {...rest}>
    {createDatetime(date).toLocaleString(format)}
  </Text>

export const DurationFormat = ({ duration, startdatetime, enddatetime, format = 'h:mm', ...rest }) =>
  <Text {...rest}>
    {Duration.fromMillis(duration || createDatetime(enddatetime) - createDatetime(startdatetime)).toFormat(format)}
  </Text>

export const HumanDuration = ({ interval, trend = false }) => {
  switch (interval) {
    case 'PT5M':
      return '5 minutes'
    case 'PT15M':
      return '15 minutes'
    case 'PT1H':
      return (trend) ? 'Hourly' : 'hour'
    case 'P1D':
      return (trend) ? 'Daily' : 'day'
    case 'P1W':
      return (trend) ? 'Weekly' : 'week'
    case 'P1M':
    default:
      return (trend) ? 'Monthly' : 'month'
  }
}

HumanDuration.propTypes = {
  interval: PropTypes.oneOf(['PT5M', 'PT15M', 'PT1H', 'P1D', 'P1W', 'P1M']),
  trend: PropTypes.bool
}

export const HumanIntervalDuration = ({ duration, interval }) => {
  const days = Math.round(duration.as('days'))

  if (days > 7) {
    // weeks - any number of days that evenly divides by 7
    if (days % 7 === 0) {
      return `${Math.round(duration.as('weeks'))} weeks`
    } else {
      // month - any number of days that evenly divides by 7
      if (interval === 'P1M') {
        return `${Math.round(duration.as('months'))} months`
      }
    }
  }

  return `${Math.round(days)} days`
}

export const IntervalDate = ({ date, interval, ...rest }) => {
  switch (interval) {
    case 'PT5M':
    case 'PT15M':
    case 'PT1H':
      return <DateTimeFormat date={date} format={DateTime.DATETIME_MED_WITH_WEEKDAY} {...rest} />
    case 'P1D':
      return <DateTimeFormat date={date} format={{...DateTime.DATE_FULL, weekday: 'long'}} {...rest} />
    case 'P1W':
      return (
        <>
          Week {date.weekNumber}, <DateTimeFormat date={date} {...rest} />
        </>
      )
    case 'P1M':
    default:
      return <DateTimeFormat date={date} format={{ month: 'long' }} {...rest} />
  }
}

export function getTimeUnit (interval) {
  console.warn('TODO - import getTimeUnit from utils/date-utils instead')
  return getTimeUnitNew(interval)
}

import { lazy, Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { AppLoader } from '../../components/app-loader'

const RoomInsights = lazy(() => import('./room-insights'))
const RoomDemandPage = lazy(() => import('./room-demand'))
const RoomPerformancePage = lazy(() => import('./room-performance'))
const NotFoundPage = lazy(() => import('../not-found/not-found-page'))

export function RoomRoutes () {
  return (
    <Suspense fallback={<AppLoader />}>
      <Routes>
        <Route path='/' element={<RoomInsights />}>
          <Route path='demand' element={<RoomDemandPage />} />
          <Route path='performance' element={<RoomPerformancePage />} />
        </Route>
        <Route path='bookings' element={<Navigate to='/booking/utilization' />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  )
}
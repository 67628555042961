import { P, Text } from '@lonerooftop/kitt-ui'
import { Message } from '../../../components/message'

export function LoginErrorMessage (props) {
  let { error, message: UserNotFoundMessage } = props

  if (!UserNotFoundMessage) {
    UserNotFoundMessage = ({ error }) =>
      <P>
        Login failed; username {error.userid} is not found. Please send an email to
        {' '}<strong><a href='mailto:PresenceIQSupport@HubStar.com'>
        PresenceIQSupport@HubStar.com</a></strong>{' '}
        and include a screenshot of this message.
      </P>
  }

  return (
    <Message type='error' title='There was an error logging in'>
      {error.reason && <P color='grey'>{error.reason}</P>}
      {error.code === 'USER_NOT_FOUND' &&
        <UserNotFoundMessage error={error} />}
    </Message>
  )
}

export function UhgUserNotFoundMessage ({ error }) {
  return (
    <Text>
      Login failed; username {error.userid} is not found. Please open a support ticket to validate your SSO role and access to this dashboard.
    </Text>
  )
}
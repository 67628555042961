import { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, H2 } from '@lonerooftop/kitt-ui'
import { ErrorMessage } from './error-message'

export class ErrorHandler extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  }

  state = { error: null }

  componentDidCatch (error, extra) {
    // console.log('ErrorHandler', { error, extra })
    this.setState({ error })
  }

  render() {
    const { error } = this.state

    if (!error) {
      return this.props.children
    }

    return (
      <Box position='center' py={3}>
        <ErrorMessage error={error} />
      </Box>
    )
  }
}

export function ReauthenticationMessage () {
  return (
    <Box
      background='black-transparent'
      position='fixed'
      top={0}
      left={0}
      right={0}
      bottom={0}
      overflow='hidden'
    >
      <Box
        background='black-dark'
        borderColor='red-dark'
        borderStyle='solid'
        p={3}
        mb={3}
        borderRadius
        position='center'
      >
        <H2 p={3} fontSize={3}>
          Session expired, re-authenticating…
        </H2>
      </Box>
    </Box>
  )
}

import { CLIENT, DEVELOPMENT, NEXT, PRODUCTION, TESTING } from '../utils/debug'

/**
 * Wrappers for our user-analytics backend
 **/
export function init () {
  if (!isEnabled()) {
    console.warn('Pendo not enabled')
    return
  }

  // see: https://agent.pendo.io/config/
  let pendo = getPendo()

  pendo.initialize({
    cookieDomain: DEVELOPMENT ? 'pie-lot.com' : 'pie-platform.com',
    // account: customer or client
    account: {
      id: CLIENT,
      environment: getEnvironment(),
    },
    visitor: {
      timezone: getTimeZone(),
    },
    // https://agent.pendo.io/config/Analytics
    analytics: {
    },
    location: {
      transforms: [
        // this transform is used to remove sensitive query keys
        {
          attr: 'search',
          action: 'ExcludeKeys',
          data: () => [ 'oracle', 'api_key', 'app_id', 'result', ]
        },
      ],
    }
  })

  console.log('Pendo initialized')
}

export function captureError (error) {
  let errorName = error?.name ?? ''
  let errorMessage = error?.message ?? ''
  let errorStack = error?.stack?.split('\n')[1] ?? ''
  let apiErrorCode = error?.code ?? ''
  let apiErrorReason = error?.reason ?? ''
  let { pathname, search } = window.location

  let payload = {
    errorName,
    errorMessage,
    errorStack,
    apiErrorCode,
    apiErrorReason,
    pathname,
    search
  }

  track('error', payload)
}

export function capturePageView (url = getUrl()) {
  if (isEnabled()) {
    let pendo = getPendo()
    pendo.pageLoad(url)
  }
}

export function capturePageNotFound () {
  let {origin, pathname, search} = window.location

  // follow same error handling as captureError
  track('error', {
    name: '404',
    message: 'Page not found',
    pathname,
    search
  })

  capturePageView(`${origin}/error/404${pathname}`)
}

export function captureQueryChange (query, previousQuery) {
  let changes = Object
    .keys({...query, ...previousQuery})
    .reduce((output, key) => {
      if (query[key] !== previousQuery[key]) {
        output[key] = query[key]
      }
      return output
    }, {})

  let keys = Object.keys(changes)
  if (keys.length === 1) {
    let [key] = keys
    let action = !!changes[key] ? 'select' : 'deselect'
    track(`${action} ${key}`, changes)
  } else {
    if (changes.startdatetime && changes.enddatetime) {
      track('select date', changes)
    } else if (changes.ids && 'zone_layer' in changes) {
      track('select ids', changes)
    } else {
      track('select options', changes)
    }
  }
}

export function captureShowUserLogin () {
  track('show login')
}

export function captureShowUserReauthentication () {
  track('show re-authentication')
}

export function captureUserClick (buttonName, options) {
  track(buttonName, options)
}

export function captureUserLogin (session) {
  if (isEnabled() && session?.userid) {
    let pendo = getPendo()
    let environment = getEnvironment()

    // see https://agent.pendo.io/public/Identity/#identify
    console.log('Pendo identify')
    pendo.identify({
      account: {
        id: CLIENT,
        name: session.client,
        environment,
      },
      visitor: {
        id: session.userid,
        timezone: getTimeZone(),
      },
    })

    track('user login', {
      buildings: Object.keys(session.allowed_methods_per_building).map(building => building.split('>').at(-1)).join(', '),
      valid_from: session.valid_from,
      valid_till: session.valid_till
    })

    capturePageView()
  }
}

export function captureUserLogout () {
  if (isEnabled()) {
    let pendo = getPendo()
    pendo.track('user logout')
    pendo.clearSession()
  }
}

export function captureWallyLoad () {
  // track('captureWallyLoad')
}

export function reset () {
  if (isEnabled()) {
    let pendo = getPendo()
    pendo.teardown()
    delete window.pendo
  }
}

/** Internal utility functions **/

function isEnabled () {
  return Boolean(
    window.pendo // only proceed if our agent is loaded
    && !window.Cypress // disable on E2E tests until we get intercept working
    && !window.location.pathname.startsWith('/test') // don't track test pages, ever
  )
}

function getEnvironment () {
  return PRODUCTION ? 'production'
    : NEXT ? 'next'
    : DEVELOPMENT ? 'development'
    : TESTING ? 'testing'
    : 'unknown'
}

function getPendo () {
  return window.pendo
}

function getTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

function getUrl () {
  return window.location.href
}

/**
 * A convenience wrapper around https://agent.pendo.io/config/
 **/
function track (eventType, options) {
  if (isEnabled()) {
    console.log(`Pendo track: "${eventType}"`, options)
    let pendo = getPendo()
    pendo.track(eventType, options)
  }
}

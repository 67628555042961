import { descending, group } from 'd3-array'
import { polygonArea } from 'd3-polygon'
import { intersection } from 'polygon-clipping'

import { UNIFIED_DATA_SOURCE_NAME } from '../constants'
import { PRODUCTION } from '../utils/debug'
import { naturalSort } from './sort'
import { getZoneTagKey } from './tag-utils'

export function defaultSpaceLayer (availableSpaceLayers) {
  const SPACE_LAYERS_BY_PRIORITY = ['SPACE_POLYGON', 'SPACE_WIFI_ZONE', 'SPACE_NEIGHBORHOOD']
  return SPACE_LAYERS_BY_PRIORITY.find(layer => availableSpaceLayers.has(layer))
}

/**
 *
 * Zone Filters
 *
 **/
export function filterByDataSource (datasource, options = { includeAllUnifiedZones: false }) {
  return (zone) => {
    if (datasource === UNIFIED_DATA_SOURCE_NAME) {
      if (options.includeAllUnifiedZones) {
        return isUnifiedZone(zone)
      } else {
        return hasFloorAggregate(zone)
      }
    } else {
      return getDataSource(zone) === datasource
    }
  }
}

export function filterBySpaceLayer (spaceLayer) {
  return (zone) => getSpaceLayer(zone) === spaceLayer
}

export function filterByCapacity (capacity) {
  return (zone) => zone.capacity.has(capacity)
}

/**
 *
 * Extracting zone(s) metadata
 *
 **/
export function getAvailableDataSources (zones) {
  let datasources = new Set(zones
    .map(getDataSource)
    .sort(naturalSort)
  )
  if (datasources.size > 1 && hasUnifiedZones(zones)) {
    datasources.add(UNIFIED_DATA_SOURCE_NAME)
  }
  return datasources
}

export function getAvailableDataSourceTypes (zones) {
  return new Set(zones.map(zone => zone.datasource.datasource_type))
}

export function getAvailableSpaceLayers (zones) {
  return new Set(zones
    .map(getSpaceLayer)
    .sort(naturalSort)
    .filter(Boolean)
  )
}

export function getDataSource (zone) {
  return zone.layer_name
}

export function getSpaceLayer (zone) {
  return zone?.metadata?.space_type
}

/**
 *
 * Extracting defaults
 *
 **/
export function getDefaultDataSource (zones, options = { includeUnified: true }) {
  if (!Array.isArray(zones) || zones.length === 0) {
    return null
  }

  // early exit
  if (zones.length === 1) {
    return zones[0].layer_name
  }

  // Process all zones once (for performance reasons).
  // This step is required to gather all information to make a
  // decision on what data source should be returned as default,
  // as determined by our business rules
  let unifiedCount = 0
  let availableDataSources = new Set()
  let defaultDataSources = new Set()
  for (let zone of zones) {
    if (options.includeUnified && isUnifiedZone(zone)) {
      unifiedCount++
    }
    availableDataSources.add(zone.layer_name)
    defaultDataSources.add(zone.default_layer)
  }

  // Depending on our business rules, return a (preferred) default data source

  // If Unified is available for all zones, we return Unified
  if (availableDataSources.size > 1 && unifiedCount === zones.length) {
    return UNIFIED_DATA_SOURCE_NAME
  }

  // Fall back on the default data source, if this set contains it
  if (defaultDataSources.size > 0) {
    for (let defaultDataSource of defaultDataSources.values()) {
      if (availableDataSources.has(defaultDataSource)) {
        return defaultDataSource
      }
    }
  }

  if (availableDataSources.size > 1) {
    // Fall back on the data source that covers the largest area
    let datasourcesByArea = Array.from(
      availableDataSources.values(),
      datasource => ({
        datasource,
        // one-line filter by data source & sum of m2
        m2: zones.reduce((totalM2, zone) => zone.layer_name === datasource ? totalM2 + zone.m2 : totalM2, 0)
      })
      // sort highest to lowest
    ).sort((a, b) => descending(a.m2, b.m2))

    // multiple data sources can cover the same area
    let largestDataSources = new Set(
      datasourcesByArea
        .filter(a => a.m2 === datasourcesByArea[0].m2)
        .map(a => a.datasource)
    )

    if (largestDataSources.size === 1) {
      return largestDataSources.values().next().value
    } else {
      // 4. Based on fixed order
      const DATASOURCES_BY_PRIORITY = ['sensorlayer', 'wifilayer', 'wifilayer2', 'bookinglayer']
      return DATASOURCES_BY_PRIORITY.find(datasource => largestDataSources.has(datasource)) ?? largestDataSources.values().next().value
    }
  } else {
    // return the only data source
    return availableDataSources.values().next().value
  }
}

export function getDefaultSpaceLayer (zones) {
  return defaultSpaceLayer(getAvailableSpaceLayers(zones))
}

export function getContainedZones (zones) {
  console.time('getContainedZones')
  let output = []

  // group by floor
  for (let [/*_*/, floorZones] of group(zones, z => z.floor_id)) {
    // console.log(floorZones[0].building_name, floorZones[0].floor_name)

    // identify datasources
    let datasources = group(floorZones, getDataSource)

    // @TODO: generalize to handle more than just sensor+wifi layer
    // e.g. wifibookinglayer + sensorlayer + wifilayer
    if (datasources.size !== 2 || !(datasources.has('sensorlayer') && datasources.has('wifilayer'))) {
      // console.log('-- no sensor+wifi datasources')
      output.push(...floorZones)
      continue
    }

    let sensorZones = datasources.get('sensorlayer')
    let wifiZones = datasources.get('wifilayer')
    // a place to store all children we've processed
    let children = []

    // loop over sensor zones to identify their potential container zones
    for (let sensorZone of sensorZones) {
      if (sensorZone.parent) {
        output.push(sensorZone)
        continue
      }

      // console.group(sensorZone.name)
      let sensorPolygon = [sensorZone.points]

      // compute intersection with all Wifi zones
      let possibleParents = wifiZones.map(wifiZone => {
        let match = intersection(sensorPolygon, [wifiZone.points])
        let area = match.length > 0 ? Math.abs(polygonArea(match[0][0])) : null
        return {
          wifiZone,
          intersection: match,
          area
        }
      })

      let parents = possibleParents.filter(match => match.area != null)

      // stand-alone zone - let's move on
      if (parents.length === 0) {
        // console.log('-- sensor zone with no parents', {sensorZone, parents, possibleParents})
        // console.assert(hasFloorAggregate(sensorZone), { msg: 'missing "floor_level_aggregate" on Sensor zone without containing Wifi zone', sensorZone })
        output.push(sensorZone)
        continue
      }

      // prefer the candidate with the largest intersecting area
      if (parents.length > 1) {
        parents.sort((a, b) => descending(a.area, b.area))
        // console.log('-- sensor zone with multiple parents', { sensorZone, parents })
      }

      let parent = parents[0].wifiZone

      // create a copy
      console.assert(sensorZone.parent == null, {sensorZone, parents})
      let child = {
        ...sensorZone,
        parent
      }

      children.push(child)
      // console.log(sensorZone.name, 'added', {child, parent})
    }

    for (let wifiZone of wifiZones) {
      if (wifiZone.children) {
        if (!PRODUCTION) {
          let wifiCapacity = wifiZone.capacity.get('default')
          let childrenCapacity = wifiZone.children.reduce((sum, zone) => sum + zone.capacity.get('default'), 0)

          // capacity
          if (wifiCapacity < childrenCapacity) {
            console.debug(
              `Wifi capacity lower than contained zones`,
              { name: wifiZone.name, wifiCapacity, childrenCapacity, wifiZone }
            )
          }

          // check floor_level_aggregate & floor_level_aggregate_subtract
          if (!hasFloorAggregate(wifiZone)) {
            console.debug(
              'Wifi zone has contained zones but does not have missing floor_level_aggregate',
              { wifiZone }
            )
          }

          if (wifiZone.children.some(c => !hasFloorAggregateSubtract(c))) {
            console.debug(
              'Sensor zones contained in Wifi zone but not each one has floor_level_aggregate_subtract',
              { wifiZone, childrenMissing: wifiZone.children.filter(c => !hasFloorAggregateSubtract(c)) }
            )
          }
        }

        output.push(wifiZone)
        continue
      }

      let wifiChildren = children.filter(z => z.parent === wifiZone)
      output.push({
        ...wifiZone,
        children: wifiChildren
      })
    }

    output.push(...children)
  }

  console.assert(output.length === zones.length, { output, zones })
  console.timeEnd('getContainedZones')

  return output
}

/**
 *
 * Boolean zone checks
 *
 **/
export function hasFloorAggregate(zone) {
  return zone.metadata.floor_level_aggregate
}

export function hasFloorAggregateSubtract(zone) {
  return zone.metadata.floor_level_aggregate_subtract
}

export function hasSpaceLayer (zone) {
  let spaceLayer = zone?.metadata?.space_type
  return typeof spaceLayer === 'string' && spaceLayer.length > 0
}

export function hasUnifiedZones (zones) {
  return zones.some(isUnifiedZone)
}

export function isBookable (zone) {
  return zone.metadata.has_bookingsource
}

export function isCollaboration (zone) {
  return !isDesk(zone) && !isMeetingRoom(zone)
}

export function isDesk (zone) {
  return zone.metadata.desk || isDeskLike(zone)
}

export function isDeskLike (zone) {
  return (
    zone.capacity.get('default') === 1
    && zone.m2 < 1.5
    && zone.layer_name === 'sensorlayer'
  )
}

export function isMeetingRoom (zone) {
  return zone.metadata.meeting_room
}

export function isUnifiedZone (zone) {
  return hasFloorAggregate(zone) || hasFloorAggregateSubtract(zone)
}

/**
 *
 * Other utils
 *
 **/
export function createZoneFilters (capacity, datasource, datasourceTypes, metadata, tags, spaceLayer) {
  let yes = () => true
  let filters = {
    capacity: (zone) => zone.capacity.has(capacity),
    datasource: (datasource === UNIFIED_DATA_SOURCE_NAME)
      ? isUnifiedZone
      : datasource
      ? (zone) => zone.layer_name === datasource
      : yes,
    datasourceTypes: (Array.isArray(datasourceTypes) && datasourceTypes.length > 0)
      ? (zone) => datasourceTypes.includes(zone.datasource.datasource_type)
      : yes,
    metadata: (Array.isArray(metadata) && metadata.length > 0)
      ? (zone) => metadata.every(key => zone.metadata?.[key] ?? false)
      : yes,
    tags: (Array.isArray(tags) && tags.length > 0)
      ? (zone) => tags.some(tag => zone.tags.includes(tag))
      : yes,
    spaceLayer: !!spaceLayer
      ? (zone) => zone.metadata?.space_type === spaceLayer
      : yes
  }

  let chain = Object.values(filters)
  filters.every = (zone) => chain.every(F => F(zone))

  return filters
}

export function totalCapacity (zones) {
  let capacityTotals = new Map()
  if (!Array.isArray(zones)) {
    return capacityTotals
  }

  console.assert(zones.every(z => z.type === 'zone'), { zones })

  let datasources = new Set(zones.map(getDataSource))
  if (datasources.size > 1) {
    if (hasUnifiedZones(zones)) {
      // console.log('Computing capacity by filtering zones with floor_level_aggregate')
      zones = zones.filter(zone => hasFloorAggregate(zone))
    } else {
      let datasource = getDefaultDataSource(zones)
      zones = zones.filter(filterByDataSource(datasource))
    }
  }

  for (let zone of zones) {
    for (let [capacityName, capacityValue] of zone.capacity) {
      let total = capacityTotals.get(capacityName) ?? 0
      capacityTotals.set(capacityName, capacityValue + total)
    }
  }

  return capacityTotals
}

export function uniqCapacities (zones) {
  let capacityNames = new Set()
  for (let zone of zones) {
    if (zone.capacity.size > 0) {
      for (let [capacityName] of zone.capacity) {
        capacityNames.add(capacityName)
      }
    }
  }

  return capacityNames
}

export function uniqTagGroups (zones) {
  let datasources = new Set(zones.map(getDataSource))
  if (datasources.size > 1) {
    if (hasUnifiedZones(zones)) {
      zones = zones.filter(zone => hasFloorAggregate(zone))
    }
  }

  let output = new Set()
  for (let zone of zones) {
    for (let tag of zone.tags) {
      output.add(getZoneTagKey(tag))
    }
  }
  return output
}

import { lazy, Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import { AppLoader } from '../../components/app-loader'

const FavoritesPage = lazy(() => import('./favorites-page'))
const NotFoundPage = lazy(() => import('../not-found/not-found-page'))
const SettingsPage = lazy(() => import('./settings-page'))

export function UserRoutes () {
  return (
    <Suspense fallback={<AppLoader />}>
      <Routes>
        <Route path='favorites' element={<FavoritesPage />} />
        <Route path='settings/*' element={<SettingsPage />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  )
}
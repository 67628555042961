
export function addOrRemove (next, current) {
  console.assert(next == null || typeof next === 'string' || Array.isArray(next))
  console.assert(Array.isArray(current))

  if (next == null) {
    return null
  } else if (Array.isArray(next)) {
    return next
  } else if (current.includes(next)) {
    return current.filter(value => value !== next)
  } else {
    return current.concat(next)
  }
}

export function addItemToDictionaryList (dictionary, key, value) {
  if (dictionary.has(key)) {
    dictionary.get(key).push(value)
  } else {
    dictionary.set(key, [value])
  }
}

export function addItemToDictionarySet (dictionary, key, value) {
  if (dictionary.has(key)) {
    dictionary.get(key).add(value)
  } else {
    dictionary.set(key, new Set([value]))
  }
}

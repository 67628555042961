import { lazy, Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import { AppLoader } from '../../components/app-loader'

const DesignPage = lazy(() => import('./design-page'))
const QueryPage = lazy(() => import('./query-page'))
const UnifiedPage = lazy(() => import('./unified-page'))

export function TestRoutes () {
  return (
    <Suspense fallback={<AppLoader />}>
      <Routes>
        <Route path='design' element={<DesignPage />} />
        <Route path='query' element={<QueryPage />} />
        <Route path='unified' element={<UnifiedPage />} />
      </Routes>
    </Suspense>
  )
}

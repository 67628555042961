import { useEffect } from 'react'
import { Box } from '@lonerooftop/kitt-ui'
import { Message } from '../../components/message'
import { Page } from '../../components/app/page'
import { capturePageNotFound } from '../../utils/analytics'

const title = 'Page not found'

export function NotFoundPage () {
  useEffect(() => {
    capturePageNotFound()
  }, [])

  return (
    <Page title={title}>
      <Box position='center'>
        <Message type='error' title={title}>
          The page you requested couldn't be found.
        </Message>
      </Box>
    </Page>
  )
}

export default NotFoundPage

import { DateTime } from 'luxon'
import { FaRegStar, FaStar } from 'react-icons/fa'
import { Button } from '../components/button'

import { useCallback } from 'react'
import { useLocation } from 'react-router'
import { usePreferences } from '../hooks/use-preferences'

import { timeframes, findTimeframe } from '../utils/timeframes'

function useFavoriteUrl () {
  let location = useLocation()
  let url = location.pathname + location.search
  let { dispatch, preferences: { favorite_urls } } = usePreferences()
  let isSaved = favorite_urls.some(f => f.to === url)
  let { searchParams } = new URL(window.location.origin + url)
  let timeframe = findTimeframe(
    DateTime.fromISO(searchParams.get('startdatetime')),
    DateTime.fromISO(searchParams.get('enddatetime')),
    Object.entries(timeframes).map(entry => entry[1])
  )

  let saveCurrentURL = useCallback(() => {
    dispatch({
      type: isSaved ? 'REMOVE_FAVORITE_URL' : 'ADD_FAVORITE_URL',
      payload: {
        date: DateTime.local().toISO(),
        name: null,
        to: url,
        timeframe: timeframe?.title ?? null
      }
    })
  }, [timeframe, isSaved, url, dispatch])

  return [ isSaved, saveCurrentURL ]
}

export function FavoriteButton () {
  let [ isSaved, saveCurrentURL ] = useFavoriteUrl()

  let icon = isSaved
    ? <FaStar />
    : <FaRegStar />

  return (
    <Button icon={icon} active={isSaved} onClick={saveCurrentURL} title='Select to save this report to your favorites list. You can find it at the bottom of the navigation sidebar.'>
      Favorite
    </Button>
  )
}

import { createContext, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Flex } from '@lonerooftop/kitt-ui'
import { FaCheck, FaPlus, FaMinus } from 'react-icons/fa'
import { Tooltip } from './tooltip'

/**
 * Wrapper for <input type='number' />
 * which enforces min and max with onChange handler
 */
export const NumberInput = ({ onChange, min, max, value, ...rest }) => {
  const onChangeHandler = (e) => {
    let input = Number(e.target.value) || 0
    if (Number.isFinite(min) && input < min) {
      input = min
    }
    if (Number.isFinite(max) && input > max) {
      input = max
    }
    onChange(input)
  }

  return (
    <input
      type='number'
      min={min}
      max={max}
      onChange={onChangeHandler}
      value={value.toString()} // removes leading zeros (eg: changes 0012 to 12)
      {...rest}
    />
  )
}

NumberInput.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  onChange:  PropTypes.func
}

export function Checkbox (props) {
  let {
    checked,
    'data-test-id': testId,
    disabled,
    label,
    onChange,
    title
  } = props

  return (
    <Tooltip as='label' title={title} className={`checkbox-control clickable${disabled ? ' disabled' : ''}`}>
      <input
        type='checkbox'
        data-test-id={testId}
        checked={checked}
        disabled={disabled}
        onChange={(e) => {
          onChange(e.target.checked)
        }}
      />
      {label}
    </Tooltip>
  )
}

export const EditableInput = (props) => {
  if (Array.isArray(props.value)) {
    return <EditableMultilineInput {...props} />
  }

  return <EditableSinglelineInput {...props} />
}

export const EditableMultilineInput = ({ value, onChange, type, ...rest }) => {
  return (
    <Box {...rest}>
      {value.map((subvalue, index) => {
        return (
          <Box key={`${index}-${subvalue}`} mb={1}>
            <EditableInput
              value={subvalue}
              onChange={changed => {
                const nextValue = value.map((unchanged, j) => {
                  if (j === index) return changed
                  return unchanged
                }).filter(v => v !== '')

                // don't use internal state, directly send confirmed changes upstream
                onChange(nextValue)
              }}
            />
          </Box>
        )}
      )}
      <EditableInput
        // force an update when a new input is added
        key={value}
        onChange={newValue => {
          const nextValue = value.concat(newValue)
          onChange(nextValue)
        }}
      />
    </Box>
  )
}

export const EditableSinglelineInput = ({ value = '', onChange, type = 'text', ...rest }) => {
  const [ next, update ] = useState(value)

  return (
    <Flex
      as='form'
      onSubmit={(e) => {
        e.preventDefault()
        onChange(next)
      }}
      {...rest}
    >
      <Box flex='auto'>
        <input
          type={type}
          value={next}
          onChange={e => {
            let change = e.target.value
            if (type === 'number') {
              change = Number(change)
            }

            update(change)
          }}
          placeholder='...'
        />
      </Box>
      <Button type='submit' disabled={next === value}>
        {value === ''
          ? <FaPlus />
          : next === ''
            ? <FaMinus />
            : <FaCheck />}
      </Button>
    </Flex>
  )
}

let RadioButtonsContext = createContext()

export function RadioButtons ({ active, children, label, onChange }) {
  let context = useMemo(() => {
    return {
      active,
      onChange: (e) => onChange(e.target.value)
    }
  }, [ active, onChange ])

  return (
    <RadioButtonsContext.Provider value={context}>
      {children}
    </RadioButtonsContext.Provider>
  )
}

export function RadioButton ({ children, value }) {
  let { active, onChange } = useContext(RadioButtonsContext)
  return (
    <Flex alignItems='center'>
      <input
        type='radio'
        id={value}
        value={value}
        checked={active === value}
        onChange={onChange}
      />
      <label htmlFor={value} className='flex__1 pl2 py1'>
        {children}
      </label>
    </Flex>
  )
}

/**
 * Component to show asterisk for required fields
 * Refer this article for explanation on displaying the asterisk in red and keeping it left aligned:
 * https://www.nngroup.com/articles/required-fields/ 
 */
export const Required = () => (
  <Tooltip title='This field is required' ml={-2} color='red'>
    <sup>*</sup>
  </Tooltip>
)

import { Icon } from '@lonerooftop/kitt-ui'
import { FaArrowAltCircleUp, FaArrowAltCircleDown, FaBroom, FaTimes } from 'react-icons/fa'

export const PeakIcon = FaArrowAltCircleUp
export const LowIcon = FaArrowAltCircleDown
export const BroomIcon = FaBroom

export function IconClear ({ children, onClear }) {
  return (
    <Icon
      className='icon-clear'
      role='button'
      aria-hidden='false'
      aria-label='Clear selected'
      tabIndex='0'
      onClick={(event) => {
        event.stopPropagation()
        onClear()
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter" || event.key === " ") {
          event.stopPropagation()
          event.preventDefault()
          onClear()
        }
      }}
    >
      {children || <FaTimes />}
    </Icon>
  )
}
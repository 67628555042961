import { useDataSource, useDataSourceTypes } from './use-page-settings'
import { useSpaces } from './use-store'
import { useActiveZones } from './use-active-zones'

import { getAvailableDataSourceTypes, hasUnifiedZones } from '../utils/zone-utils'
import { naturalSort } from '../utils/sort'
import { UNIFIED_DATA_SOURCE_NAME } from '../constants'
import memo from 'memoize-one'

/**
 *
 * Provides access to the available data sources & selected data source.
 * User settings are retrieved from the URL and will prevail.
 * If the selected data source isn't available or valid, return the preferred data source.
 *
 **/
export function useActiveDataSource () {
  let datasource = useDataSource()
  let datasourceTypes = useDataSourceTypes()
  let { buildings } = useSpaces()
  let zones = useActiveZones()

  return getActiveDataSource(
    datasource,
    datasourceTypes,
    buildings,
    zones
  )
}

/**
 *
 * @TODO: merge with `zone-utils/getDefault
 **/
export let getActiveDataSource = memo((activeDataSource, activeDataSourceTypes, buildings, zones) => {
  console.time('useActiveDataSource')
  // get the possible values for datasources & datasource types
  let availableDataSources = _getAvailableDataSources(buildings, zones)
  let availableDataSourceTypes = getAvailableDataSourceTypes(zones)
  let availableLayers = getAvailableLayers(zones, availableDataSources)
  let defaultLayer = _getDefaultLayer(buildings)

  // set preferred or default if no (valid) selection exists
  if (!availableLayers.has(activeDataSource)) {
    let first = Array.from(availableDataSources)[0]
    if (availableDataSources.has(UNIFIED_DATA_SOURCE_NAME)) {
      // console.debug('auto-select unified datasource', UNIFIED_DATA_SOURCE_NAME)
      activeDataSource = UNIFIED_DATA_SOURCE_NAME
    } else if (availableDataSources.has(defaultLayer)) {
      // console.debug('auto-select building preferred datasource', defaultLayer)
      activeDataSource = defaultLayer
    } else {
      // console.debug('auto-select first datasource', first)
      activeDataSource = first
    }
  }

  console.timeEnd('useActiveDataSource')
  console.assert((buildings.length > 0 || zones.length > 0) ? availableDataSources.has(activeDataSource) : true, { activeDataSource, availableDataSources, zones, buildings })

  return {
    activeDataSource,
    availableDataSources,
    activeDataSourceTypes,
    availableDataSourceTypes,
    availableLayers
  }
})

export function getAvailableLayers (zones, availableDataSources) {
  return new Map(
    Array.from(
      availableDataSources,
      datasource => [
        datasource,
        new Set(
          datasource === UNIFIED_DATA_SOURCE_NAME ? null :
          zones
            .filter(zone => zone.layer_name === datasource)
            .map(zone => zone.datasource.datasource_type)
        )
      ]
    )
  )
}

function _getAvailableDataSources (buildings, zones) {
  // we check zone layers from the building, since zones might be filtered
  // (e.g. in the Heatmap, we show building/floor but allow user to select a zone)
  let datasources = new Set(buildings
    .flatMap(building => building.zone_layers)
    .map(layer => layer.name)
    .sort(naturalSort)
  )

  if (datasources.size > 1 && hasUnifiedZones(zones)) {
    datasources.add(UNIFIED_DATA_SOURCE_NAME)
  }
  return datasources
}

function _getDefaultLayer (input) {
  if (Array.isArray(input)) {
    let defaultLayers = new Set(input.map(_getDefaultLayer))
    if (defaultLayers.size === 1) {
      return defaultLayers.values().next().value
    }
  } else if (input) {
    return input.settings?.default_layer
  }
}
import { UserDataContext } from '../context/user-data'

import { useContext, useLayoutEffect } from 'react'
import { useSession } from '@lonerooftop/kitt-data-react'
import { useLocalStorage } from './use-localstorage'

import { getColors, getColorScale } from '../utils/color-utils'
import darkmode from '../utils/dark-mode'

import { COLOR_SCHEMES, DESIGN_MODES } from '../constants'

export function usePreferences () {
  return useContext(UserDataContext)
}

export function usePreferedColors () {
  return usePreferences().preferences.data_color_palette
}

export function useColors () {
  return getColors(usePreferedColors())
}

export function useColorScale () {
  return getColorScale(usePreferedColors())
}

export function useColorScheme () {
  let palette = usePreferedColors()
  let scheme = COLOR_SCHEMES[palette] ?? COLOR_SCHEMES.regular
  return scheme
}

export function useDarkmode () {
  let [ darkMode, update ] = useLocalStorage(`dark_mode`)
  console.assert(DESIGN_MODES.has(darkMode), 'invalid value for darkMode')

  useLayoutEffect(() => {
    // console.log('set theme', darkMode)
    darkmode.set(darkMode)
  }, [ darkMode ])

  return [ darkMode, update ]
}

export function useConfigurableFeatureFlags () {
  let { session } = useSession()
  return session?.userid.startsWith('lrt-saml')
}
import { Loader } from '@lonerooftop/kitt-ui'
import { ErrorMessage } from './components/error-message'
import { StoreContext, ClientConfigContext, TimeMarkersContext } from './context'
import { UserDataContextProvider } from './context/user-data'
import { LoginPage } from './sections/auth/pages/login-page'
import { Sections } from './sections/index'

export function App (props) {
  console.log('render App', props)
  let {
    config,
    error,
    loading,
    session,
    store,
    userdata,
    time_markers
  } = props

  if (session) {
    if (session.session) {
      return (
        <StoreContext.Provider value={store}>
          <UserDataContextProvider userdata={userdata}>
            <ClientConfigContext.Provider value={config}>
              <TimeMarkersContext.Provider value={time_markers}>
                <Sections />
              </TimeMarkersContext.Provider>
            </ClientConfigContext.Provider>
          </UserDataContextProvider>
        </StoreContext.Provider>
      )
    }

    if (session.error) {
      return <LoginPage {...props.session} />
    }
  }

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ErrorMessage error={error} position='center' />
  }
}

import { DateTime, Interval } from 'luxon'

const today = DateTime.local().startOf('day')
const monday = DateTime.local().startOf('week')
const firstOfMonth = DateTime.local().startOf('month')
const isMonday = today.equals(monday)

export const timeframes = {
  today: {
    title: 'Today',
    start: today,
    end: today.plus({ days: 1 })
  },

  yesterday: {
    title: 'Yesterday',
    start: today.minus({ days: 1 }),
    end: today
  },

  lastWorkDay: {
    title: 'Last work day',
    start: today.minus({ days: isMonday ? 3 : 1 }),
    end: isMonday ? today.minus({ days: 2 }) : today
  },

  pastSevenDays: {
    title: 'Past 7 days',
    start: today.minus({ days: 7 }),
    end: today
  },

  lastWeek: {
    title: 'Last week',
    start: monday.minus({ weeks: 1 }),
    end: monday
  },

  thisMonth: {
    title: 'Current month',
    start: today.startOf('month'),
    end: today
  },

  lastMonth: {
    title: 'Last month',
    start: firstOfMonth.minus({ months: 1 }),
    end: firstOfMonth
  },

  lastQuarter: {
    title: 'Last quarter',
    start: today.startOf('quarter').minus({ quarters: 1 }),
    end: today.startOf('quarter'),
  },

  pastTwoWeeks: pastXWeeks(2),
  pastFourWeeks: pastXWeeks(4),
  pastSixWeeks: pastXWeeks(6),
  pastEightWeeks: pastXWeeks(8),
  pastTwelveWeeks: pastXWeeks(12),

  pastThirtyDays: {
    title: 'Past 30 days',
    start: monday.minus({ days: 30 }),
    end: monday
  },

  pastSixtyDays: {
    title: 'Past 60 days',
    start: monday.minus({ days: 60 }),
    end: monday
  },

  pastNinetyDays: {
    title: 'Past 90 days',
    start: monday.minus({ days: 90 }),
    end: monday
  },

  pastThreeMonths: {
    title: 'Past 3 months',
    start: firstOfMonth.minus({ months: 3 }),
    end: firstOfMonth
  },

  pastSixMonths: {
    title: 'Past 6 months',
    start: firstOfMonth.minus({ months: 6 }),
    end: firstOfMonth
  },

  pastYear: {
    title: 'Past year',
    start: firstOfMonth.minus({ months: 12 }),
    end: firstOfMonth.minus({ days: 1 })
  },
}

Object.values(timeframes).forEach(timeframe => {
  if (timeframe.start && timeframe.end) {
    timeframe.interval = Interval.fromDateTimes(timeframe.start, timeframe.end)
  }
})

export function pastXWeeks (weeks) {
  const start = monday.minus({ weeks })
  const end = monday
  return {
    title: `Past ${weeks} weeks`,
    start,
    end,
    interval: Interval.fromDateTimes(start, end)
  }
}

export function getTimeframe (key) {
  return timeframes[key]
}

export function findTimeframe (startdatetime, enddatetime, options) {
  if (Array.isArray(options)) {
    return options.find(option =>
      option?.start?.hasSame(startdatetime, 'day')
      && option?.end?.hasSame(enddatetime, 'day')
    )
  }

  return null
}

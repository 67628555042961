import { forwardRef, useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Flex, H2, Icon } from '@lonerooftop/kitt-ui'
import { FaChevronDown } from 'react-icons/fa'

/**
 *
 * Cards consists of the following elements, each of which can have different backgrounds/colors:
 * - CardWrapper (required)
 *  - CardHeader (optional)
 *    - CardTitle (optional)
 *  - CardContent (optional)
 *
 * Color is determined by the 'variant' property:
 * - default = transparent (no color, only paddings & margins)
 * - highlight (for messages or highlighted parts)
 * - highlight-transparent
 * - primary style (a solid color for the primary piece of content)
 * - secondary (a transparent color for use in sidebars or less important elements)
 * - white (for elements that need to have a white background)
 *
 * Additionally, cards can be one of the following:
 * - expandable (title shown & content hidden)
 *
**/

export const CardWrapper = forwardRef(function CardWrapper (props, ref) {
  let {
    children,
    expandable,
    invert,
    isExpanded,
    title,
    transparent,
    variant,
    ...rest
  } = props

  let className = 'card'
  if (variant) {
    className += ' card-' + variant
  }

  if (expandable) {
    className += ' card-expandable'
    className += ` card-${isExpanded ? '' : 'not-'}expanded`
  }

  return (
    <Box ref={ref} mb={3} {...rest} className={className}>
      {title ? <CardHeader title={title} /> : null}
      {children}
    </Box>
  )
})

CardWrapper.defaultProps = {
  variant: 'transparent'
}

CardWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary', 'highlight', 'transparent' ]).isRequired,
  title: PropTypes.node
}

export function CardHeader ({ title, children, ...rest }) {
  return (
    <Box {...rest} className='card-header'>
      {typeof title === 'string'
        ? <CardTitle>{title}</CardTitle>
        : title}
      {children}
    </Box>
  )
}

CardHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node
}

export function CardTitle ({ children, ...rest }) {
  // CardTitle only contains non-layout props (so no margin/padding).
  // This makes it easy to use other title styles as drop-in replacement.
  return (
    <H2
      fontSize={6}
      uppercase={true}
      letterSpacing={2}
      mb={0}
      {...rest}
      className='card-title'
    >
      {children}
    </H2>
  )
}

CardTitle.propTypes = {
  children: PropTypes.node
}

export function CardContent ({ children, ...rest }) {
  return (
    <Box {...rest} className='card-content'>
      {children}
    </Box>
  )
}

CardContent.propTypes = {
  children: PropTypes.node
}

export function CardActions ({ children, ...rest }) {
  return (
    <Flex alignItems='center' {...rest} className='card-actions'>
      {children}
    </Flex>
  )
}

CardActions.propTypes = {
  children: PropTypes.node
}

/**
 *
 * Expandable Card
 *
 **/

export function ExpandableCard (props) {
  let {
    children,
    expandable,
    defaultExpanded,
    title,
    ...rest
  } = props
  let { isExpanded, toggle } = useExpandable(defaultExpanded)

  if (!expandable) {
    return <CardWrapper title={title} {...rest}>{children}</CardWrapper>
  }

  return (
    <CardWrapper expandable isExpanded={isExpanded} {...rest}>
      <ExpandableCardHeader
        isExpanded={isExpanded}
        title={title}
        toggle={toggle}
      />
      <ExpandableCardContent isExpanded={isExpanded}>
        {children}
      </ExpandableCardContent>
    </CardWrapper>
  )
}

ExpandableCard.defaultProps = {
  expandable: true,
  defaultExpanded: false
}

ExpandableCard.propTypes = {
  children: PropTypes.node,
  expandable: PropTypes.bool,
  defaultExpanded: PropTypes.bool
}

function ExpandableCardHeader (props) {
  let { toggle, isExpanded, title } = props
  switch (typeof title) {
    case 'string':
      return (
        <CardHeader onClick={toggle}>
          <CardTitle>
            <ExpandableIcon isExpanded={isExpanded} />
            {title}
          </CardTitle>
        </CardHeader>
      )
    case 'function':
      return title({ toggle, isExpanded })
    default:
      return (
        <CardHeader display='flex' alignItems='center' onClick={toggle}>
          <ExpandableIcon isExpanded={isExpanded} />
          {title}
        </CardHeader>
      )
  }
}

function useExpandable (defaultExpanded) {
  let [ isExpanded, setExpanded ] = useState(defaultExpanded)
  let toggle = useCallback(() => {
    setExpanded(value => !value)
  }, [ setExpanded ])
  return { isExpanded, toggle }
}

export function ExpandableIcon ({ isExpanded, onClick }) {
  return (
    <Icon className='icon-expandable' onClick={onClick}>
      <FaChevronDown className={isExpanded ? 'rotatable rotate-180' : 'rotatable'} />
    </Icon>
  )
}

function ExpandableCardContent ({ children, isExpanded }) {
  return (
    <div className='card-expandable-wrapper' aria-hidden={!isExpanded}>
      <div className='card-expandable-content'>
        {children}
      </div>
    </div>
  )
}

import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { Box, Flex, P } from '@lonerooftop/kitt-ui'
import { Button } from './button'
import { Message } from './message'
import { isLoginError } from '@lonerooftop/kitt-data'
import { ExpandableCard } from './cards'
import { captureError } from '../utils/analytics'

/**
 *
 * This component is responsible for displaying a
 * user-friendly error message.
 * Additionally, it will send the error to our user-analytics backend.
 *
 **/
export function ErrorMessage ({ error, position }) {
  let previous = useRef()

  useEffect(() => {
    if (!error) {
      return
    }

    // only process the first error
    let current = Array.isArray(error) ? error[0] : error

    // don't log the same error twice
    if (previous.value === current) {
      return
    }

    // store the current error
    previous.value = current

    if (current && !isLoginError(current)) {
      // console.error(current)
      captureError(current)
    }
  }, [error])

  if (!error) {
    return null
  }

  let title = null
  let message = null
  let errorDetails = null

  // console.log('error', error)

  if (Array.isArray(error) && error.every((e) => e.code && e.reason)) {
    if (error.some(isLoginError)) {
      // auto-login
      return null
    }

    title = <ApiErrorTitle />
    errorDetails = (
      <>
        <P>The API responded with the following errors:</P>
        {error.map((error, index) => (
          <ApiErrorMessage error={error} key={`${error.code}--${index}`} />
        ))}
      </>
    )
  }

  if (error.code && error.reason) {
    if (isLoginError(error)) {
      // auto-login
      return null
    }

    title = <ApiErrorTitle />
    errorDetails = (
      <>
        <P>The API responded with the following error:</P>
        <ApiErrorMessage error={error} />
      </>
    )
  }

  if (error instanceof Error) {
    if (/AbortError/i.test(error.name)) {
      title = 'The request took too long and was aborted'
    } else if (/Network ?Error|Failed to fetch/i.test(error.message)) {
      title = <NetworkErrorTitle />
      message = <NetworkErrorMessage />
      errorDetails = <JsErrorMessage error={error} />
    } else {
      title = <JsErrorTitle />
      errorDetails = <JsErrorMessage error={error} />
    }
  }

  let reportURL = new URL(window.location.href).toString()

  return (
    <Box className="message-content" position={position}>
      <Message type="error" title={GenericErrorTitle}>
        <P>{title}</P>
        {message}
        <NotifyIssueMessage reportURL={reportURL} />
        <ErrorDetailsSection errorDetails={errorDetails} reportURL={reportURL} />
        <hr />
        <GenericErrorButtons />
      </Message>
    </Box>
  )
}

ErrorMessage.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.instanceOf(Error),
  ]),
}

function ErrorDetailsSection({ errorDetails, reportURL }) {
  return (
    <ExpandableCard title="Error details" fontSize={6}>
      <Box px={3}>
        <P>{`Report URL: ${reportURL}`}</P>
        {errorDetails}
      </Box>
    </ExpandableCard>
  )
}

const GenericErrorTitle = 'Oops, something went wrong'

function NotifyIssueMessage({ reportURL }) {
  const subject = 'There’s an issue with a report in Hubstar Utilization'
  const body = `The URL for this report is: ${reportURL}`

  return (
    <>
      <P fontSize={5}>
        Please notify the Support team of this issue at{' '}
        <strong>
          <a
            href={`mailto:PresenceIQSupport@HubStar.com?subject=${encodeURIComponent(
              subject
            )}&body=${encodeURIComponent(body)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            PresenceIQSupport@HubStar.com
          </a>
        </strong>
      </P>
    </>
  )
}

function GenericErrorButtons() {
  return (
    <Flex my={3}>
      <Button mr={2} onClick={() => window.location.reload()}>
        Reload
      </Button>
      <Button mr={2} onClick={() => window.location.assign(window.location.pathname)}>
        Reset
      </Button>
      <Button ml={2} onClick={() => window.location.assign('/')}>
        Go to homepage
      </Button>
    </Flex>
  )
}

function ApiErrorTitle() {
  return "It seems that you've discovered a bug in our code."
}

function ApiErrorMessage({ error }) {
  return (
    <>
      <P mb={0}>
        Error code: <code>{error.code}</code>
      </P>
      <P>{error.reason}</P>
    </>
  )
}

ApiErrorMessage.propTypes = {
  error: PropTypes.shape({
    code: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
  }).isRequired,
}

function JsErrorTitle() {
  return "It seems that you've discovered a bug in our code."
}

function JsErrorMessage({ error }) {
  // console.error(error)

  return (
    <>
      <P mb={0}>
        Error name: <code>{error.name}</code>
      </P>
      <P>
        Error message: <code>{error.message}</code>
      </P>
    </>
  )
}

function NetworkErrorTitle() {
  return 'There was a problem with your network connection.'
}

function NetworkErrorMessage() {
  return (
    <>
      <P>Please check the following:</P>
      <ul>
        <li>Is your internet connection working properly?</li>
        <li>Do you have VPN enabled? If so, try disabling it.</li>
        <li>
          Sometimes this is caused by a request that took too long to complete.
          You might try reloading the page.
        </li>
      </ul>
    </>
  )
}

import { group } from 'd3-array'
import { naturalSort } from './sort'

/**
 * These functions work with individual zone tags,
 * e.g.
 *  neighbourhood.Red 1.3
 *  Spacetype.Conference
 **/
const CUSTOM_TAG_SEPARATOR = '•'
export function isValidTag (tag) {
  return (
    typeof tag === 'string'
    && tag.length > 2
    && /[.:]{1}/.test(tag)
    && !/^(custom|hide|UID)/gi.test(tag)
  )
}

export function splitZoneTag (tag) {
  // legacy support for zones that contain colons, such as AAB Ghustav Mahlerlan
  if (!isValidTag(tag)) {
    console.log('invalid tag', { tag })
    return []
  }

  // replace (only) the first instance of the separator, then split on that
  // this will preserve tags like "spacetype.s. bench"
  return tag.replace('.', CUSTOM_TAG_SEPARATOR).replace(':', CUSTOM_TAG_SEPARATOR).split(CUSTOM_TAG_SEPARATOR)
}

export function getZoneTagKey (tag) {
  return splitZoneTag(tag)?.[0] ?? null
}

export function getZoneTagValue (tag) {
  return splitZoneTag(tag)?.[1] ?? null
}

export function filterByTag (input, exact) {
  function matchInput (tag) {
    if (exact) {
      return tag === input
    } else {
      return tag.includes(input)
    }
  }

  return function filterZonesByTag (zones) {
    return zones.filter(zone =>
      zone.tags.some(matchInput)
    )
  }
}

export function groupTagsByKey (tags) {
  return group(tags, getZoneTagKey)
}

export function isGroupTag (tag) {
  return Boolean(
    isValidTag(tag)
    && !tag?.endsWith('.')
    && !tag?.endsWith(':')
    && !tag?.endsWith('.tag')
  )
}

/**
 *
 * Returns a unique, sorted array of zone tags
 * Only returns tags that are used for grouping
 *
 **/
export function mapZonesToTags (zones) {
  let tags = new Set()
  zones.forEach(zone => {
    zone.tags.filter(isGroupTag).forEach(tag => {
      tags.add(tag)
    })
  })
  return Array.from(tags).sort(naturalSort)
}

export function zonesToTags (zones) {
  // unique tags with array of zones that match that tag
  let zonesByTag = new Map()

  // unique tag groups with set of tags that match that group
  let tagHierarchy = new Map()

  // populate our maps
  zones.forEach(zone => {
    zone.tags.forEach(tag => {
      let [ key, value ] = splitZoneTag(tag)
      if (!key || !value) return

      if (!zonesByTag.has(tag)) {
        zonesByTag.set(tag, [])
      }

      zonesByTag.get(tag).push(zone)

      if (!tagHierarchy.has(key)) {
        tagHierarchy.set(key, new Set())
      }
      tagHierarchy.get(key).add(tag)
    })
  })

  return {
    zonesByTag,
    tagHierarchy: Array.from(tagHierarchy).sort(([ groupA ], [ groupB ]) => naturalSort(groupA, groupB))
  }
}
import { CX, Box, Icon, Text } from '@lonerooftop/kitt-ui'
import { forwardRef } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Tooltip } from './tooltip'

export const Button = forwardRef(function Button (props, ref) {
  let {
    active,
    children,
    className,
    disabled,
    icon,
    onClick,
    name,
    title,
    to,
    type,
    value,
    variant,
    'aria-label': ariaLabel,
    'aria-haspopup': ariaHaspopup,
    'data-test-id': testId,
  } = CX.Text(props)

  console.assert(icon || children, { msg: 'Empty button', props})
  let notClickable = !to && !onClick

  if (active) {
    className += ' button-active'
  }

  if (disabled) {
    className += ' button-disabled'
  }

  if (notClickable) {
    className += ' not-clickable'
  }

  if (variant) {
    className += ` button-${variant}`
  }

  if (!children) {
    if (icon && !title) {
      className += ' button-icon'
      children = <Icon>{icon}</Icon>
    }

    if (icon && title) {
      className += ' button-icon'
      children = <Tooltip as={Icon} title={title}>{icon}</Tooltip>
    }
  } else {
    if (icon && !title) {
      children = <><Icon mr={2} align='middle'>{icon}</Icon><Text display='inline-block' align='middle'>{children}</Text></>
    }
    if (icon && title) {
      className += ' button-tooltip'
      children = <Tooltip className='button-text' as={Text} title={title}><Icon mr={2}>{icon}</Icon><Text display='inline-block' align='middle'>{children}</Text></Tooltip>
    }

    if (title && !icon) {
      className += ' button-tooltip'
      children = <Tooltip className='button-text' title={title}>{children}</Tooltip>
    }
  }

  if (to) {
    return (
      <NavLink
        ref={ref}
        data-test-id={testId}
        to={to}
        className={({ isActive }) => `${className}${isActive ? ' button-active' : ''}`}
        aria-label={ariaLabel}
        aria-haspopup={ariaHaspopup}
      >
        {children}
      </NavLink>
    )
  }

  return (
    <button
      disabled={disabled || notClickable}
      name={name}
      onClick={onClick}
      ref={ref}
      data-test-id={testId}
      type={type}
      className={className}
      value={value}
      aria-label={ariaLabel}
    >
      {children}
    </button>
  )
})

Button.defaultProps = {
  defaultClassName: 'button',
}

Button.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'transparent', 'link', 'dropdown']),
  'aria-label': PropTypes.string,
  'aria-haspopup': PropTypes.string,
}

export let DropdownButton = props => <Button {...props} variant='dropdown' />
export let LinkButton = props => <Button {...props} variant='link' />
export let PrimaryButton = props => <Button {...props} variant='primary' />
export let TransparentButton = props => <Button {...props} variant='transparent' />

export function ButtonPills ({ children, ...props }) {
  return (
    <Box {...props} className='button-pills'>
      {children}
    </Box>
  )
}

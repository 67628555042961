import {
  useCapacity,
  useDataSource,
  useDataSourceTypes,
  useMetadata,
  useTags,
} from './use-page-settings'
import { useActiveSpaceLayer } from './use-active-space-layer'
import { createZoneFilters } from '../utils/zone-utils'

export function useActiveFilters () {
  let capacity = useCapacity()
  let datasource = useDataSource()
  let datasourceTypes = useDataSourceTypes()
  let metadata = useMetadata()
  let tags = useTags()
  let spaceLayer = useActiveSpaceLayer()

  let filters = createZoneFilters(
    capacity,
    datasource,
    datasourceTypes,
    metadata,
    tags,
    spaceLayer
  )

  return {
    filters,
    capacity,
    datasource,
    datasourceTypes,
    metadata,
    tags,
    spaceLayer
  }
}
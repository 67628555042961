import PropTypes from 'prop-types'
import { FaExclamationCircle, FaExclamationTriangle, FaInfoCircle, FaQuestionCircle } from 'react-icons/fa'

import { Box, H4, Flex, Icon } from '@lonerooftop/kitt-ui'
import { IconClear } from './icons'

import { useCallback } from 'react'
import { useLocalStorage } from '../hooks/use-localstorage'

export function Message ({ children, title, type, ...props }) {
  return (
  	<Box mb={3} p={3} {...props} className={`message message-${type}`}>
      {title ?
        <>
          <H4 data-test-id='message-title' display='flex' className='gap-2' alignItems='center' mb={1}>
            <MessageIcon type={type} />
            {title}
          </H4>
          {children && (
            <div className='message-content'>
              {children}
            </div>
          )}
        </>
        : (
          <Flex alignItems='flex-start'>
            <Icon className='message-icon' mr={3}><MessageIcon type={type} /></Icon>
            <div className='message-content'>
              {children}
            </div>
          </Flex>
        )}
    </Box>
  )
}

Message.propTypes = {
  title: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['info', 'explanation', 'alert', 'warning', 'error']).isRequired
}

Message.defaultProps = {
  type: 'info'
}

function MessageIcon ({ type }) {
  switch (type) {
    case 'info':
      return <FaInfoCircle />
    case 'explanation':
      return <FaQuestionCircle />
    case 'alert':
      return <FaExclamationCircle />
    case 'warning':
      return <FaExclamationTriangle />
    case 'error':
      return <FaExclamationTriangle />
    default:
      return null
  }
}

export function ClosableMessage (props) {
  let { children, id, title, persistent, type, ...rest } = props
  let storage = persistent ? window.localStorage : window.sessionStorage
  let [ invisible, setInvisible ] = useLocalStorage(`close-${id}`, false, storage)

  let onClick = useCallback(() => {
    setInvisible(true)
  }, [ setInvisible ])

  if (invisible) {
    return null
  }

  return (
    <Box mb={3} position='relative' p={3} {...rest} className={`message message-closable message-${type}`}>
      <IconClear onClear={onClick} />
      <H4 display='flex' className='gap-2' alignItems='center' mb={1}>
        <MessageIcon type={type} />
        {title}
      </H4>
      <div className='message-content'>
        {children}
      </div>
    </Box>
  )
}

ClosableMessage.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['info', 'explanation', 'alert', 'warning', 'error']).isRequired
}

import { lazy, Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import { AppLoader } from '../../components/app-loader'

import { QUERY_PARAMS } from '../../constants'
import { pastXWeeks } from '../../utils/timeframes'

const AnalyseAttendancePage = lazy(() => import('./analyse-attendance'))
const AnalyseBookingDataPage = lazy(() => import('./analyse-booking-data'))
const BookingImportReportsPage = lazy(() => import('./booking-import-reports'))
const BookingPopularityPage = lazy(() => import('./booking-popularity'))
const BookingRatesOverTime = lazy(() => import('./booked-rates-over-time'))
const BookingRatesRoomPage = lazy(() => import('./booked-rates-per-room'))
const BookingUtilizationPage = lazy(() => import('./booking-utilization'))
const NotFoundPage = lazy(() => import('../not-found/not-found-page'))

const settings = {
  initial: {
    [QUERY_PARAMS.STARTDATETIME]: pastXWeeks(4).start.toISODate(),
    [QUERY_PARAMS.ENDDATETIME]: pastXWeeks(4).end.toISODate(),
    [QUERY_PARAMS.GROUP_BY]: 'building',
    [QUERY_PARAMS.OPENING_HOURS]: 'opening_hours',
    [QUERY_PARAMS.ZONE_METADATA]: ['has_bookingsource']
  },
}

export function BookingRoutes () {
  return (
    <Suspense fallback={<AppLoader />}>
      <Routes>
        <Route path='analyse' element={<AnalyseBookingDataPage settings={settings} />} />} />
        <Route path='attendance' element={<AnalyseAttendancePage settings={settings} />} />
        <Route path='utilization' element={<BookingUtilizationPage settings={settings} />} />
        <Route path='popularity' element={<BookingPopularityPage settings={settings} />} />
        <Route path='booked-rates-over-time' element={<BookingRatesOverTime settings={settings} />} />
        <Route path='booked-rates-per-room' element={<BookingRatesRoomPage settings={settings} />} />
        <Route path='import-reports' element={<BookingImportReportsPage settings={settings} />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  )
}

import { DropdownMenu } from '../dropdown'

import { useCallback } from 'react'
import { useSpaces } from '../../hooks/use-store'
import { useSetIds } from '../../hooks/use-page-settings'

import { naturalSortBy } from '../../utils/sort'
import { getId } from '../../utils/space-utils'

export function SelectQueryBuilding ({ multiple }) {
  let { campuses, buildings } = useSpaces()
  let onChange = useSetIds(multiple)

  let onClear = useCallback(
    () => onChange(campuses.map(getId)),
    [onChange, campuses]
  )

  if (campuses.length === 0) {
    return null
  }

  let items = campuses.flatMap(
    (campus) => campus.buildings.map(building => ({
      key: building.id,
      title: building.name,
      disabled: building.zone_layers.length === 0
    })))
    .sort(naturalSortBy('title'))

  let selected = buildings.map(getId)

  return (
    <DropdownMenu
      items={items}
      multiple={multiple}
      onChange={onChange}
      onClear={selected.length === items.length ? undefined : onClear}
      selected={selected}
      title='Building'
    />
  )
}

SelectQueryBuilding.defaultProps = {
  multiple: false
}

SelectQueryBuilding.propTypes = {
  multiple: DropdownMenu.propTypes.multiple
}

import { Loader } from '@lonerooftop/kitt-ui'
import { ErrorMessage } from '../../../components/error-message'
import { LoginBox } from '../components/login-box'
import { LoginErrorMessage, UhgUserNotFoundMessage } from '../components/login-error-message'

export function LoginPage ({ config, error, session }) {
  if (session) {
    return null
  }

  if (error) {
    if (error.code) {
      /* eslint-disable no-fallthrough */

      switch (error.code) {
         // Api key no longer valid
        case 'API_KEY_TIMED_OUT':
        // Problem with API key
        case 'API_KEY_INVALID':
        // No Api key or oracle provided
        case 'API_KEY_MISSING':
        // User has no authorizations (at this ip)
        case 'API_KEY_NO_AUTHORIZATION':
        // The provided oracle is too old
        case 'ORACLE_TIMED_OUT':
        // The provided oracle is invalid
        case 'ORACLE_INVALID':
          return <LoginBox config={config} />
        // Client-side-only:
        case 'AUTOMATIC_LOGIN':
          return <Loader loading='Authenticating…' />
        case 'USER_NOT_FOUND':
          let message = undefined
          if (config['get-api-key-url'].includes('https://login.uhg.pie-platform.com')) {
            message = UhgUserNotFoundMessage
          }

          return (
            <LoginBox config={config}>
              <LoginErrorMessage error={error} message={message} />
            </LoginBox>
          )
        default: {
          return <ErrorMessage error={error} position='center' />
        }
      }
      /* eslint-enable no-fallthrough */
    }

    // non-API errors
    return <ErrorMessage error={error} position='center' />
  }

  return <Loader />
}

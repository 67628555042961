import { captureUserClick } from '../utils/analytics'

class DarkMode {
  #mq = null;

  constructor () {
    if (window && 'matchMedia' in window) {
      this.#mq = window.matchMedia('(prefers-color-scheme: dark)')
    } else {
      // No matchMedia available, dark mode cannot be set
    }
  }

  init () {
    // set the default value & don't capture it
    this.set(null, false)

    let mq = this.#mq

    if (mq) {
      mq.addEventListener('change', () => {
        this.set(this._getFromLocalStorage())
      })
    }
  }

  set (value, capture = true) {
    let system = this._getFromOS()
    let stored = this._getFromLocalStorage()
    let final = value || stored || system
    let current = document.documentElement.dataset.colorMode

    if (value === 'system') {
      final = system
    }

    if (final === current) {
      return // nothing to do
    }

    if (capture) {
      captureUserClick('select theme', { mode: final })
    }

    document.documentElement.dataset.colorMode = final
  }

  _getDefault () {
    return document.documentElement.getAttribute('data-color-mode')
  }

  _getFromOS () {
    let mq = this.#mq
    if (mq) {
      return mq.matches ? 'dark' : 'light'
    } else {
      return this._getDefault()
    }
  }

  _getFromLocalStorage () {
    let stored
    if (window && 'localStorage' in window) {
      stored = JSON.parse(window.localStorage.getItem('dark_mode'))
    }

    return stored || this._getDefault()
  }
}

let darkmode = new DarkMode()

export default darkmode

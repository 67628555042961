import { useEffect, useRef } from 'react'
import { debounce } from '../utils/debounce'

/**
 * This hook will restore the scroll position of an HTML element.
 * - id: string, used to store the scroll position in sessionStorage
 * - key: when the key is changed, the scroll position will be stored
 *
 * Make sure to pass the returned ref to the HTML element.
 * Example:

 let { pathname } = useLocation()
  let ref = usePersistentScrollPosition({
    id: 'menu-scroll-position',
    key: pathname
  })

 **/
export function usePersistentScrollPosition ({ id, key }) {
  let ref = useRef()

  // setup scroll listener & save the scroll position
  useEffect(() => {
    let { current } = ref

    function save (e) {
      let { scrollTop } = e.target
      console.debug('saving scroll position', scrollTop)
      window.sessionStorage.setItem(id, scrollTop)
    }

    // create scroll handler
    let onScroll = debounce(save, 250)

    // subscribe
    current?.addEventListener('scroll', onScroll)

    return () => {
      // unsubscribe
      current?.removeEventListener('scroll', onScroll)
    }
  }, [ id ])

  // when the key is updated, restore the scroll position
  useEffect(() => {
    let scrollTop = window.sessionStorage.getItem(id) || 0
    if (scrollTop !== ref?.current?.scrollTop) {
      console.debug('restore scroll position', scrollTop)
      ref.current.scrollTop = scrollTop
    }
  }, [ id, key ])

  return ref
}
import { useOpeningHours, useOpeningDays, useOpeningEnd, useOpeningStart } from './use-page-settings'
import { usePreferences } from './use-preferences'
import { useSpaces } from './use-store'

import { createTimes, createDays, getOpeningHoursValue } from '../utils/opening-hours-utils'
import { DEFAULT_OPENING_HOURS } from '../constants'

export function useActiveOpeningHours () {
  let { preferences: { opening_hours } } = usePreferences()
  let { buildings } = useSpaces()
  let selected = useOpeningHours()
  let activeOpeningHoursId = selected ?? opening_hours
  let activeOpeningHours = getOpeningHoursValue(activeOpeningHoursId, buildings[0])
  let start = useOpeningStart()
  let end = useOpeningEnd()
  let days = createDays(useOpeningDays(), activeOpeningHours)
  let times = createTimes(start, end)
  let activeOpeningHoursPattern = days.flatMap(day => times.map(hour =>
    `${day} ${hour.toString().padStart(2, '0')}:00-${(hour + 1).toString().padStart(2, '0')}:00`
  ))
  let activeOpeningHoursPatternString = activeOpeningHoursPattern.length > 0 && `${days.join(',')} ${times[0].toString().padStart(2, '0')}:00-${(times[times.length - 1] + 1).toString().padStart(2, '0')}:00`
  let relevantDataOpeningHours = activeOpeningHoursId === 'custom' ? activeOpeningHoursPatternString : DEFAULT_OPENING_HOURS[activeOpeningHoursId]

  return {
    // the key (e.g. "core_hours")
    activeOpeningHoursId,
    // the value (e.g. "Mo-Fr 09:00-17:00")
    activeOpeningHours,
    // repeated opening hours pattern based on array of days & start/end times
    activeOpeningHoursPattern,
    // generated value based on array of days & start/end times
    activeOpeningHoursPatternString,
    // returns custom or predefined opening hours based on selected opening hours key
    relevantDataOpeningHours,
    // selected days (e.g. ["Mo", "Tu", "We"])
    days,
    times
  }
}

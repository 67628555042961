import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { capturePageView, captureQueryChange } from '../utils/analytics'

export function Analytics () {
  let location = useLocation()
  let ref = useRef(location)

  useEffect(() => {
    let previous = ref.current

    if (previous.pathname !== location.pathname) {
      console.debug('navigate', { from: previous.pathname, to: location.pathname })
      capturePageView()
    } else if (previous.search !== location.search) {
      let query = Object.fromEntries(new URLSearchParams(location.search).entries())
      let previousQuery = Object.fromEntries(new URLSearchParams(previous.search).entries())
      captureQueryChange(query, previousQuery)
    }

    ref.current = location

  }, [ location, ref ])

  return null
}
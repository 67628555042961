// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
import { LOCALE } from '../constants'

let collator = new Intl.Collator(
  LOCALE,
  { numeric: true, sensitivity: 'base' }
)

export const naturalSort = collator.compare

export function naturalSortBy (key) {
  return (a, b) => naturalSort(a[key], b[key])
}
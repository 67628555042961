import { Fragment, useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { Button, Icon, Text, Flex } from '@lonerooftop/kitt-ui'
import { FaCaretDown, FaCheck, FaTimes, FaRegCheckCircle, FaRegCircle } from 'react-icons/fa'
import { IconClear } from './icons'
import { Tooltip } from './tooltip'

import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useListNavigation,
  useInteractions,
  FloatingFocusManager,
  useTypeahead,
  flip,
  size,
  autoUpdate,
  FloatingPortal
} from '@floating-ui/react'

export function DropdownMenu (props) {
  let {
    'data-test-id': testId,
    disabled,
    forceTitle,
    items,
    multiple,
    onChange,
    onClear,
    selected,
    title,
    fullWidth,
  } = props

  const [isOpen, setIsOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)

  const { x, y, strategy, refs, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      flip(),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            minWidth: `${rects.reference.width}px`
          })
        },
      })
    ]
  })

  const itemTitles = items.map(item => (item && !item.disabled) ? item.title : null)
  const listRef = useRef([])
  const listContentRef = useRef(itemTitles)
  const isTypingRef = useRef(false)

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'listbox' })
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    loop: false
  })
  const typeahead = useTypeahead(context, {
    listRef: listContentRef,
    activeIndex,
    onMatch: setActiveIndex,
    onTypingChange(isTyping) {
      isTypingRef.current = isTyping
    }
  })

  const {
    getReferenceProps,
    getFloatingProps,
    getItemProps
  } = useInteractions([click, dismiss, role, listNav, typeahead])

  if (!Array.isArray(selected)) {
    selected = [ selected ]
  }

  function isSelected (item) {
    if (!multiple && selected.length > 1) {
      return false
    }
    return selected.includes(item.key)
  }

  let selectable = items.filter(i => i?.key)
  let selectedItems = selected.map(key => selectable.find(item => item.key === key)).filter(Boolean)

  let menuTitle
  if (forceTitle) {
    menuTitle = title
  } else if (selectedItems.length === 0) {
    menuTitle = title
  } else if (selectedItems.length === 1) {
    let [first] = selectedItems
    menuTitle = first?.selectedTitle ?? first.title
  } else if (selectedItems.length === selectable.length) {
    // menuTitle = `(All ${title.toLowerCase()}s)`
    menuTitle = title
  } else if (selectedItems.length > 1) {
    menuTitle = `(${selectedItems.length} selected)`
  }

  let ClearIcon = multiple === 'deselect' ? FaRegCheckCircle : FaTimes
  let CheckedIcon = multiple ? FaRegCheckCircle : FaCheck
  let UncheckedIcon = multiple ? FaRegCircle : Fragment

  let containerClass = [
    'button',
    'button-dropdown',
    selectedItems.length > 0 && ' button-dropdown-selected',
    fullWidth && 'width-100'
  ].filter(Boolean).join(' ')

  return(
    <>
      <Button
        tabIndex={0}
        ref={refs.setReference}
        className={containerClass}
        aria-autocomplete='none'
        data-test-id={testId}
        disabled={disabled || items.length === 0}
        {...getReferenceProps({
          role: 'button'
        })}
      >
        <Flex alignItems='center'>
          {menuTitle &&
            <Text truncate pr={2}>
              {menuTitle}
            </Text>
          }
          {!menuTitle && items.length === 0 && (
            <Text truncate pr={2}>
              None available
            </Text>
          )}
          <Icon>
            <FaCaretDown className={isOpen ? 'rotatable rotate-180' : 'rotatable'} />
          </Icon>
          {selectedItems.length > 0 && onClear &&
            <IconClear onClear={onClear}>
              <ClearIcon />
            </IconClear>
          }
        </Flex>
      </Button>
      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              aria-multiselectable={multiple}
              className={'dropdown-list'}
              ref={refs.setFloating}
              data-test-id={`${testId}-options`}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                zIndex: 1000
              }}
              {...getFloatingProps()}
            >

            {items.map((item, index) => {
              if (item === null) {
                return <hr aria-hidden='true' key={`${index}-hr`} />
              }

              let { disabled, key, title, tooltipTitle } = item

              let handleChange = (key) => {
                item.onChange ? item.onChange(key) : onChange(key)
                setIsOpen(false)
              }

              if (tooltipTitle) {
                title = (
                  <Tooltip
                    className='tooltip-text'
                    title={tooltipTitle}
                    placement='left'
                  >
                    {title}
                  </Tooltip>
                )
              }

              if (key === null && title) {
                return (
                  <div
                    role='option'
                    tabIndex={-1}
                    aria-disabled={true}
                    aria-selected={false}
                    className='dropdown-list-option not-clickable'
                    key={`${index}-${key}`}>
                    {title}
                  </div>
                )
              }

              let selected = isSelected(item)

              return (
                <div
                  role='option'
                  aria-selected={!!selectedItems.find(item => item.key === key)}
                  aria-disabled={disabled}
                  key={`${index}-${key}`}
                  tabIndex={index === activeIndex ? 0 : -1}
                  ref={(node) => {
                    listRef.current[index] = node
                  }}
                  className={`dropdown-list-option ${disabled ? ' disabled' : ''}${selected ? ' active' : ''}${activeIndex ? ' active-index' : ''}`}
                  {...getItemProps({
                    onClick() {
                      if (!disabled) {
                        handleChange(key)
                      }
                    },
                    onKeyDown(event) {
                      if (event.key === 'Enter') {
                        event.preventDefault()
                        if (!disabled) {
                          handleChange(key)
                        }
                      }
                      if (event.key === ' ' && !isTypingRef.current) {
                        event.preventDefault()
                        if (!disabled) {
                          handleChange(key)
                        }
                      }
                    }
                  })}
                >
                  <span className='icon'>
                    {selected ? <CheckedIcon /> : <UncheckedIcon />}
                  </span>
                  {title}
                </div>
              )
            })}

            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  )
}

DropdownMenu.defaultProps = {
  disabled: false,
  forceTitle: false,
  items: [],
  multiple: false,
  selected: [],
  fullWidth: false
}

DropdownMenu.propTypes = {
  // if this is true, the menu button will always display the title instead of the selected item(s)
  forceTitle: PropTypes.bool,
  // disable the dropdown (still shows the button)
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.exact({
    // this is the key that will be used for selecting items
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    onChange: PropTypes.func,
    // if this item is selected, display this title
    selectedTitle: PropTypes.string,
    // title used to display inside the dropdown buttons
    title: PropTypes.node.isRequired,
    // optional tooltip that will be displayed when hovering over this item
    tooltipTitle: PropTypes.node,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool
})).isRequired,
  // enable multi-select
  multiple: PropTypes.oneOf([true, false]).isRequired,
  // function callback when item is selected, will receive the value of the `key`
  onChange: PropTypes.func,
  // function that will be called to clear selection
  onClear: PropTypes.func,
  // one or more selected keys
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number)
  ]),
  title: PropTypes.node
}
import { Page } from '../../../components/app/page'
import { Box } from '@lonerooftop/kitt-ui'
import { MessagePermissionDenied } from '../../../components/message-permission-denied'

export function UnauthorizedPage () {
  return (
    <Page title='Permission denied'>
      <Box position='center'>
        <MessagePermissionDenied />
      </Box>
    </Page>
  )
}

export default UnauthorizedPage
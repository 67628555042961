import PropTypes from 'prop-types'

import { Box, Icon, Text } from '@lonerooftop/kitt-ui'
import { FaInfoCircle } from 'react-icons/fa'

import { useState, useRef } from 'react'
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  autoPlacement,
  shift,
  limitShift,
  hide,
  useHover,
  safePolygon,
  useClick,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  arrow,
  FloatingPortal,
  FloatingArrow
} from '@floating-ui/react'

export function Tooltip (props) {
  let {
    children,
    title,
    titleWidth,
    delayHide,
    delayShow,
    interactive,
    placement,
    trigger,
    ...boxProps
  } = props

  const [isOpen, setIsOpen] = useState(false)
  const arrowRef = useRef(null)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: placement,
    middleware: [
      offset(8),
      shift({
        limiter: limitShift({
          offset: 15,
        }),
        padding: 5,
      }),
      placement ? flip() : autoPlacement(),
      arrow({
        element: arrowRef,
      }),
      hide(),
    ],
    whileElementsMounted: autoUpdate,
  })

  const referenceHidden = context.middlewareData.hide?.referenceHidden

  const hover = useHover(context, {
    enabled: trigger === 'hover',
    delay: {
      open: delayShow,
      close: delayHide,
    },
    move: false,
    handleClose: interactive ? safePolygon() : null,
  })
  const click = useClick(context, { enabled: trigger === 'click' })
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'tooltip' })
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    click,
    focus,
    dismiss,
    role,
  ])

  if (!title) {
    return <Box {...boxProps}>{children}</Box>
  }

  return (
    <Box
      {...boxProps}
      ref={refs.setReference}
      {...getReferenceProps()}
    >
      {children}
      <FloatingPortal>
        {isOpen && (
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
            className={`tooltip-${referenceHidden ? 'hidden' : 'visible'} tooltip-container tooltip-container-${titleWidth}`}
          >
            <FloatingArrow ref={arrowRef} context={context} strokeWidth={1} stroke='var(--tooltip-border)' fill='var(--tooltip-background)' />
            {title}
          </div>
        )}
      </FloatingPortal>
    </Box>
  )
}

Tooltip.defaultProps = {
  delayHide: 0,
  delayShow: 0,
  interactive: false,
  placement: undefined,
  trigger: 'hover',
  titleWidth: 'medium'
}

Tooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  titleWidth: PropTypes.oneOf(['medium', 'large']),
  delayHide: PropTypes.number,
  delayShow: PropTypes.number,
  interactive: PropTypes.bool,
  placement: PropTypes.oneOf([undefined, 'top', 'top-start', 'top-end', 'right', 'right-start', 'right-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end']),
  trigger: PropTypes.oneOf(['click', 'hover']),
}

export function TextTooltip (props) {
  return <Tooltip as={Text} {...props} />
}

export function TooltipIcon (props) {
  return (
    <Icon className='tooltip-icon cursor-tooltip' as='sup' px={2} fontSize={7} {...props}>
      <FaInfoCircle />
    </Icon>
  )
}

export function DelayedTooltip (props) {
  return <Tooltip delayShow={1000} {...props} />
}

export function MaybeTooltip ({ title, children, ...tooltipProps }) {
  if (title) {
    return (
      <Tooltip
        {...tooltipProps}
        title={title}
      >
        {children}
      </Tooltip>
    )
  } else {
    return children
  }
}

import { DropdownMenu } from '../dropdown'

import { useMetadata, useSetMetadata } from '../../hooks/use-page-settings'
import { useSpaceHierarchy } from '../../hooks/use-store'

import { formatMetadata } from '../../utils/format'

import { ZONE_METADATA_FILTERS } from '../../constants'

export function SelectQueryMetadata () {
  let setZoneMetadata = useSetMetadata()
  let metadata = useMetadata()
  let zones = Array.from(useSpaceHierarchy().zones.values())

  return (
    <SelectMetadata
      metadata={metadata}
      setZoneMetadata={setZoneMetadata}
      zones={zones}
    />
  )
}

export function SelectMetadata (props) {
  let { metadata, setZoneMetadata, zones } = props

  let items = []
  ZONE_METADATA_FILTERS.forEach((metadataKey) => {
    let matching = zones.filter(z => z.metadata[metadataKey])
    items.push({
      key: metadataKey,
      title: `${formatMetadata(metadataKey)} (${matching.length})`,
      disabled: matching.length === 0
    })
  })

  return (
    <DropdownMenu
      disabled={items.every(item => item.disabled)}
      items={items}
      selected={metadata}
      onChange={setZoneMetadata}
      onClear={setZoneMetadata}
      multiple
      title='Metadata'
    />
  )
}

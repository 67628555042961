import { DateTime, Duration, Interval } from 'luxon'
import { roundBy } from './format'
import { getOpeningHoursOverlapWithDates } from './opening-hours-utils'

const dateCache = new Map()
const intervalCache = new Map()

// window.dateCache = dateCache
// window.intervalCache = intervalCache

export function createDatetime (value) {
  if (DateTime.isDateTime(value)) {
    return value
  } else if (dateCache.has(value)) {
    return dateCache.get(value)
  } else if (typeof value === 'string') {
    let parsed = DateTime.fromISO(value)
    dateCache.set(value, DateTime.fromISO(value))
    return parsed
  } else if (value instanceof Date) {
    return DateTime.fromJSDate(value)
  } else {
    return DateTime.now()
  }
}

export function createApiDatetime (value, space) {
  let date = createDatetime(value)
  return date.setZone(space.timezone, { keepLocalTime: true }).toJSON()
}

export function createDuration (startdatetime, enddatetime) {
  let invalid = Duration.invalid('No valid start and/or end date specified')
  let start = createDatetime(startdatetime)
  let end = createDatetime(enddatetime)

  if (!startdatetime || !enddatetime || !start?.isValid || !end?.isValid) {
    return invalid
  }

  return Duration.fromMillis(end - start)
}

export function createInterval (startdatetime, enddatetime) {
  let start = createDatetime(startdatetime)
  let end = createDatetime(enddatetime)

  if (!start?.isValid || !end?.isValid || start >= end) {
    // console.log({startdatetime, enddatetime}, start.toISO(), end.toISO(), start >= end)
    throw new RangeError('No valid start and/or end date specified')
  }

  let key = `${start.toISODate()}/${end.toISODate()}`
  if (intervalCache.has(key)) {
    return intervalCache.get(key)
  } else {
    let interval = Interval.fromISO(key)
    intervalCache.set(key, interval)
    return interval
  }

}

export function createToday () {
  return createDatetime().startOf('day')
}

export function createTomorrow () {
  return createToday().plus({ days: 1 })
}

export function createMonday () {
  return createToday().startOf('week')
}

export function createFirstOfMonth () {
  return createToday().startOf('month')
}

export function clampDateToNow (date, roundedMinutes = 5) {
  if (!DateTime.isDateTime(date) || !date.isValid) {
    throw new Error('Invalid input')
  }

  let now = DateTime.local().startOf('minute').setZone(date._zone, { keepLocalTime: true })

  // we only need to clamp future dates
  if (date < now) {
    return date
  }

  let minutes = roundBy(now.minute, roundedMinutes)
  if (minutes % roundedMinutes === 0) {
    minutes -= roundedMinutes
  }

  let clamped = now.startOf('hour').plus({ minutes })
  return clamped
}

export function getPreviousDates (start, end, opening_hours) {
  const duration = Duration.fromMillis(end - start)
  const days = Math.round(duration.as('days'))

  let previous_start = start.minus({ days })
  let previous_end = end.minus({ days })
  let next_start = start.plus({ days })
  let next_end = end.plus({ days })

  if (start.day === 1 && end.day === 1) {
    previous_start = start.minus({ months: Math.round(days / 30) })
    previous_end = end.minus({ months: Math.round(days / 30) })
    next_start = start.plus({ months: Math.round(days / 30) })
    next_end = end.plus({ months: Math.round(days / 30) })
  }

  if (days > 0 && days < 3 && opening_hours) {
    let prev_overlap = getOpeningHoursOverlapWithDates(opening_hours, previous_start, Duration.fromMillis(previous_end - previous_start))
    let next_overlap = getOpeningHoursOverlapWithDates(opening_hours, next_start, Duration.fromMillis(next_end - next_start))

    while (!prev_overlap) {
      previous_start = previous_start.minus({ days })
      previous_end = previous_end.minus({ days })

      prev_overlap  = getOpeningHoursOverlapWithDates(opening_hours, previous_start, Duration.fromMillis(previous_end - previous_start))
    }

    while (!next_overlap) {
      next_start = next_start.plus({ days })
      next_end = next_end.plus({ days })

      next_overlap = getOpeningHoursOverlapWithDates(opening_hours, next_start, Duration.fromMillis(next_end - next_start))
    }
  }

  return { previous_start, previous_end, next_end, next_start }
}

export function getTimeUnit (interval) {
  switch (interval) {
    case 'PT5M':
    case 'PT15M':
      return 'minutes'
    case 'PT1H':
      return 'hours'
    case 'P1D':
      return 'days'
    case 'P1W':
      return 'weeks'
    case 'P1M':
      return 'months'
    case 'INF':
      return 'all time'
    default:
      throw new Error('Unknown interval ' + interval)
  }
}

export function getNumberOfDays (datetimes) {
  if (!Array.isArray(datetimes)) {
    throw new Error('no datetimes')
  }

  if (datetimes.length <= 1) {
    return 0
  }

  let start = datetimes[0]
  let end = datetimes[datetimes.length -1]
  let duration = Duration.fromMillis(end - start)
  return Math.round(duration.as('days'))
}

export function getNumberOfHours (datetimes) {
  if (datetimes.length <= 1) {
    return 0
  }

  let start = datetimes[0]

  // every datetime is a new day
  if (start.day !== datetimes[1].day) {
    return 24
  } else {
    let nextDayIndex = datetimes.findIndex(d => start.day !== d.day)
    let end = datetimes[nextDayIndex] ?? datetimes[datetimes.length - 1]
    return Math.round(end.hour + 1 - start.hour)
  }
}

export function parseDate (dirtyDate) {
  return DateTime.fromISO(dirtyDate, { setZone: true })
}

export function parseDates (arrayOfDateStrings) {
  return arrayOfDateStrings.map(parseDate)
}

export function validateDates (startDate, endDate) {
  if (!startDate && !endDate) {
    return null
  }

  let start = createDatetime(startDate)
  let end = createDatetime(endDate)
  let today = DateTime.now().set({ minutes: 0, seconds: 0, milliseconds: 0 })

  if (start > end) {
    [start, end] = [end, start]
  }

  if (start > today) {
    start = today.minus({ days: 1 })
  } 

  if (end > today) {
    end = today
  }

  if (start.equals(end)) {
    start = start.minus({ days: 1 })
  }

  return {
    startdatetime: start.toISODate(),
    enddatetime: end.toISODate()
  }
}
